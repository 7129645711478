import React, { useEffect, useRef } from 'react'
import rb1 from '../../assets/images/rb1.webp'
import rb2 from '../../assets/images/rb2.webp'
import rb3 from '../../assets/images/rb3.webp'
import rb4 from '../../assets/images/rb4.webp'
import rb5 from '../../assets/images/rb5.webp'
import rb6 from '../../assets/images/rb6.webp'
import rb7 from '../../assets/images/rb7.webp'
import rb8 from '../../assets/images/rb8.webp'
import rb9 from '../../assets/images/rb9.webp'
import rb10 from '../../assets/images/rb10.webp'
import rb11 from '../../assets/images/rb11.webp'
import rb12 from '../../assets/images/rb12.webp'
import rb13 from '../../assets/images/rb13.webp'

const predefinedPositions = {
  desktop: [
    { x: -40, y: -20 },
    { x: -30, y: 3 },
    { x: -20, y: -23 },
    { x: 10, y: 39 },
    { x: 0, y: -25 },
    { x: 30, y: 30 },
    { x: 45, y: 39 },
    { x: -45, y: 35 },
    { x: 38, y: 0 },
    { x: 23, y: -14 },
    { x: 45, y: -26 },
    { x: -7, y: 24 },
    { x: -24, y: 35 }
  ],
  tablet: [
    { x: -40, y: -14 },
    { x: -44, y: 3 },
    { x: -20, y: -13 },
    { x: 10, y: 16 },
    { x: 0, y: -10 },
    { x: 30, y: 21 },
    { x: 45, y: 18 },
    { x: -45, y: 19 },
    { x: 38, y: 0 },
    { x: 23, y: -14 },
    { x: 45, y: -14 },
    { x: -7, y: 11 },
    { x: -24, y: 19}
  ],
  mobile: [
    { x: -40, y: -20 },
    { x: -38, y: 10 },
    { x: -22, y: -31 },
    { x: 10, y: 44 },
    { x: 0, y: -25 },
    { x: 30, y: 22 },
    { x: 38, y: 39 },
    { x: -41, y: 45 },
    { x: 41, y: 5 },
    { x: 23, y: -14 },
    { x: 45, y: -26 },
    { x: -5, y: 18 },
    { x: -19, y: 34 }
  ],
}

const ResultsBanner = () => {

  const imagesRef = useRef([]);

  useEffect(() => {
    const getScreenType = () => {
      if (window.innerWidth <= 768) return 'mobile';
      if (window.innerWidth <= 1024) return 'tablet';
      return 'desktop';
    };

    const screenType = getScreenType();
    const positions = predefinedPositions[screenType];

    const setFixedPosition = (img, index) => {
      const { x, y } = positions[index];
      img.style.transform = `translate(${x}vw, ${y}vh)`;
    };

    // imagesRef.current.forEach((img, index) => {
    //   img.style.opacity = 1;
    //   img.style.transition = 'transform 5s ease-out';
    //   setFixedPosition(img, index);
    // });

    imagesRef.current.forEach((img, index) => {
      if (img) {
        setFixedPosition(img, index);
      }
    });
     // Optionally, you can add a listener to handle window resizing
     const handleResize = () => {
      const newScreenType = getScreenType();
      if (newScreenType !== screenType) {
        imagesRef.current.forEach((img, index) => {
          if (img) {
            setFixedPosition(img, index);
          }
        });
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);

  return (
    <div className='result-banner' >
      <div className='result-overlay'></div>
      <div className='result-heading' data-aos="zoom-in" data-aos-duration="1500">
        <h1 className='banner-heading mb-4'>Results speak</h1>
        <h5 className='banner-sub-heading '>Our Community Members  have many stories to share</h5>
      </div>
      <div className='result-bg-images '>
        <img className='result-image' src={rb1} alt='' ref={el => imagesRef.current[0] = el} />
        <img className='result-image' src={rb2} alt='' ref={el => imagesRef.current[1] = el} />
        <img className='result-image' src={rb3} alt='' ref={el => imagesRef.current[2] = el} />
        <img className='result-image' src={rb4} alt='' ref={el => imagesRef.current[3] = el} />
        <img className='result-image' src={rb5} alt='' ref={el => imagesRef.current[4] = el} />
        <img className='result-image' src={rb6} alt='' ref={el => imagesRef.current[5] = el} />
        <img className='result-image' src={rb7} alt='' ref={el => imagesRef.current[6] = el} />
        <img className='result-image' src={rb8} alt='' ref={el => imagesRef.current[7] = el} />
        <img className='result-image' src={rb9} alt='' ref={el => imagesRef.current[8] = el} />
        <img className='result-image' src={rb10} alt='' ref={el => imagesRef.current[9] = el} />
        <img className='result-image' src={rb11} alt='' ref={el => imagesRef.current[10] = el} />
        <img className='result-image' src={rb12} alt='' ref={el => imagesRef.current[11] = el} />
        <img className='result-image' src={rb13} alt='' ref={el => imagesRef.current[12] = el} />

      </div>
    </div>
  )
}

export default ResultsBanner