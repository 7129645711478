import React, { useEffect } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scroll(0, 0);
}, []);
  return (
    <div className="outer-pages">
      <Navbar />
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div
              style={{
                textAlign: "left",
                paddingTop: "120px",
              }}
              className="privacypolicy-topdiv"
            >
              <div>
                <div>
                  <p>
                    <strong>
                      <span style={{ fontSize: "24pt" }}>
                        Privacy Policy&nbsp;
                      </span>
                    </strong>
                  </p>
                  <p>
                    <span style={{ fontSize: "12pt" }}>
                      This privacy policy (&ldquo;Policy&rdquo;) relates to the
                      manner SOCIAL EAGLE PRIVATE LIMITED (&ldquo;we&rdquo;,
                      &ldquo;us&rdquo;, &ldquo;our&rdquo;) in which we use,
                      handle and process the data that you provide us in
                      connection with using the products or services we offer.
                      By using this website or by availing goods or services
                      offered by us, you agree to the terms and conditions of
                      this Policy, and consent to our use, storage, disclosure,
                      and transfer of your information or data in the manner
                      described in this Policy.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontSize: "12pt" }}>
                      We are committed to ensuring that your privacy is
                      protected in accordance with applicable laws and
                      regulations. We urge you to acquaint yourself with this
                      Policy to familiarize yourself with the manner in which
                      your data is being handled by us.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontSize: "12pt" }}>
                      SOCIAL EAGLE PRIVATE LIMITED may change this Policy
                      periodically and we urge you to check this page for the
                      latest version of the Policy in order to keep yourself
                      updated.
                    </span>
                  </p>
                  <p>This website is not part of the Facebook website or Facebook Inc. Additionally, this site is not endorsed by Facebook in any way. Facebook is a trademark of Facebook, Inc.</p>
                  <p>
                    <strong>
                      <span style={{ fontSize: "14pt" }}>
                        What data is being collected
                      </span>
                    </strong>
                  </p>
                  <p>
                    <span style={{ fontSize: "12pt" }}>
                      We may collect the following information from you:
                    </span>
                  </p>
                  <p>
                    <span style={{ fontSize: "12pt" }}>
                      <ul>
                        <li>Name</li>
                        <li>
                          Contact information including address and email
                          address
                        </li>
                        <li>
                          Demographic information or, preferences or interests
                        </li>
                        <li>
                          Personal Data or Other information relevant/ required
                          for providing the goods or services to you
                        </li>
                        <li>
                          {" "}
                          The meaning of Personal Data will be as defined under
                          relevant Indian laws
                        </li>
                      </ul>
                    </span>
                  </p>
                </div>
              </div>
              <div>
                <div>
                  <p>
                    <strong>
                      <span style={{ fontSize: "12pt" }}>Note</span>
                    </strong>
                    :<br />
                  </p>
                  <p>
                    <span style={{ fontSize: "12pt" }}>
                      Notwithstanding anything under this Policy as required
                      under applicable Indian laws, we will not be storing any
                      credit card, debit card or any other similar card data of
                      yours. Please also note that all data or information
                      collected from you will be strictly in accordance with
                      applicable laws and guidelines.
                    </span>
                  </p>
                  <p>
                    <strong>
                      <span style={{ fontSize: "14pt" }}>
                        What we do with the data we gather
                      </span>
                    </strong>
                  </p>
                  <p>
                    <span style={{ fontSize: "12pt" }}>
                      We require this data to provide you with the goods or
                      services offered by us including but not limited, for the
                      below set out purposes:
                    </span>
                  </p>
                  <p>
                    <span style={{ fontSize: "12pt" }}>
                      <ul>
                        <li>Internal record keeping.</li>
                        <li>For improving our products or services.</li>
                        <li>
                          For providing updates to you regarding our products or
                          services including any special offers. To communicate
                          information to you
                        </li>
                        <li>
                          For internal training and quality assurance purposes
                        </li>
                      </ul>
                    </span>
                  </p>
                  <p>
                    <strong>
                      <span style={{ fontSize: "14pt" }}>
                        Who do we share your data with
                      </span>
                    </strong>
                  </p>
                  <p>
                    <span style={{ fontSize: "12pt" }}>
                      We may share your information or data with:
                    </span>
                  </p>
                  <p>
                    <span style={{ fontSize: "12pt" }}>
                      <ul>
                        <li>
                          Third parties including our service providers in order
                          to facilitate the provisions of goods or services to
                          you, carry out your requests, respond to your queries,
                          fulfil your orders or for other operational and
                          business reasons.
                        </li>
                        <li>
                          With our group companies (to the extent relevant)
                        </li>
                        <li>
                          <span style={{ fontSize: "12pt" }}>
                            Our auditors or advisors to the extent required by
                            them for performing their services
                          </span>
                        </li>
                        <li>
                          <p>
                            <span style={{ fontSize: "12pt" }}>
                              Governmental bodies, regulatory authorities, law
                              enforcement authorities pursuant to our legal
                              obligations or compliance requirements.
                            </span>
                          </p>
                        </li>
                      </ul>
                    </span>
                  </p>
                </div>
              </div>
              <div>
                <div>
                  <p></p>
                </div>
              </div>
            </div>
            <div
              title="Page 2"
              style={{ textAlign: "left", paddingBottom: "60px" }}
            >
              <div>
                <div>
                  <p>
                    <strong>
                      <span style={{ fontSize: "14pt" }}>
                        How we use cookies
                      </span>
                    </strong>
                  </p>
                  <p>
                    <span style={{ fontSize: "12pt" }}>
                      We use &quot;cookies&quot; to collect information and to
                      better understand customer behaviour. You can instruct
                      your browser to refuse all cookies or to indicate when a
                      cookie is being sent. However, if you do not accept
                      cookies, you may not be able to avail our goods or
                      services to the full extent. We do not control the use of
                      cookies by third parties. The third party service
                      providers have their own privacy policies addressing how
                      they use such information.
                    </span>
                  </p>
                  <p>
                    <strong>
                      <span style={{ fontSize: "14pt" }}>
                        Your rights relating to your data
                      </span>
                    </strong>
                  </p>
                  <p>
                    <strong>
                      <em>
                        <span style={{ fontSize: "12pt" }}>
                          Right to Review&nbsp;
                        </span>
                      </em>
                    </strong>
                    <span style={{ fontSize: "12pt" }}>
                      - You can review the data provided by you and can request
                      us to correct or amend such data (to the extent feasible,
                      as determined by us). That said, we will not be
                      responsible for the authenticity of the data or
                      information provided by you.
                      <br />
                    </span>
                    <strong>
                      <em>
                        <span style={{ fontSize: "12pt" }}>
                          Withdrawal of your Consent&nbsp;
                        </span>
                      </em>
                    </strong>
                    <span style={{ fontSize: "12pt" }}>
                      - You can choose not to provide your data, at any time
                      while availing our goods or services or otherwise withdraw
                      your consent provided to us earlier, in writing to our
                      email ID: sathish@socialeagle.inIn the event you choose to
                      not provide or later withdraw your consent, we may not be
                      able to provide you our services or goods.Please note that
                      these rights are subject to our compliance with applicable
                      laws.
                    </span>
                  </p>
                  <p>
                    <strong>
                      <span style={{ fontSize: "14pt" }}>
                        How long will we retain your information or data?
                      </span>
                    </strong>
                  </p>
                  <p>
                    <span style={{ fontSize: "12pt" }}>
                      We may retain your information or data (i) for as long as
                      we are providing goods and services to you; and (ii) as
                      permitted under applicable law, we may also retain your
                      data or information even after you terminate the business
                      relationship with us. However, we will process such
                      information or data in accordance with applicable laws and
                      this Policy.
                    </span>
                  </p>
                  <p>
                    <strong>
                      <span style={{ fontSize: "14pt" }}>Data Security</span>
                    </strong>
                  </p>
                  <p>
                    <span style={{ fontSize: "12pt" }}>
                      We will use commercially reasonable and legally required
                      precautions to preserve the integrity and security of your
                      information and data.
                    </span>
                  </p>
                  <p>
                    <strong>
                      <span style={{ fontSize: "14pt" }}>
                        Queries/ Grievance Officer
                      </span>
                    </strong>
                  </p>
                  <p>
                    <span style={{ fontSize: "12pt" }}>
                      For any queries, questions or grievances about this
                      Policy, please contact us using the contact information
                      provided on this website.
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
