import React, { useEffect, useRef, useState } from 'react'
import RoadMap from '../../assets/images/road-map.webp'
import RoadMapEnd from '../../assets/images/road-map-end.webp'
import { useLocation, useNavigate } from 'react-router-dom'
import C1 from '../../assets/images/course/c1.webp'
import C2 from '../../assets/images/course/c2.webp'
import C3 from '../../assets/images/course/c3.webp'
import C4 from '../../assets/images/course/c4.webp'
import C5 from '../../assets/images/course/c5.webp'
import C6 from '../../assets/images/course/c6.webp'
import C7 from '../../assets/images/course/c7.webp'
import C8 from '../../assets/images/course/c8.webp'
import C9 from '../../assets/images/course/c9.webp'
import C10 from '../../assets/images/course/c10.webp'
import C11 from '../../assets/images/course/c11.webp'
import C12 from '../../assets/images/course/c12.webp'
import C13 from '../../assets/images/course/c13.webp'
import C14 from '../../assets/images/course/c14.webp'
import C15 from '../../assets/images/course/c15.webp'
import C16 from '../../assets/images/course/c16.webp'
import C17 from '../../assets/images/course/c17.webp'
const CourseContent = () => {

    const [activeTab, setActiveTab] = useState(0);
    const videoRefs = useRef([]);
    const observerRef = useRef(null);

    // Set up Intersection Observer to detect which video is in view
    useEffect(() => {
        if (observerRef.current) observerRef.current.disconnect();

        observerRef.current = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const index = videoRefs.current.indexOf(entry.target);
                    if (index !== -1) {
                        setActiveTab(index);
                    }
                }
            });
        }, { threshold: 0.5 }); // Adjust threshold as needed

        videoRefs.current.forEach(video => {
            if (video) observerRef.current.observe(video);
        });

        return () => {
            if (observerRef.current) observerRef.current.disconnect();
        };
    }, []);

    const handleTabClick = (index) => {
        setActiveTab(index);
        if (videoRefs.current[index]) {
            const offsetTop = videoRefs.current[index].getBoundingClientRect().top + window.pageYOffset - 100; // Adjust -100 to your desired offset
            window.scrollTo({
                top: offsetTop,
                behavior: 'smooth',
            });
        }
    };

    return (
        <div>

            <div className='course-road-map overflow-hidden'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-8 d-lg-block d-md-block d-none'>
                            <div className='position-relative'>
                                <img className='road-map-img' src={RoadMap} alt="" />
                                <div>
                                    <div className='road-map-content'>
                                        <h5 className='road-map-text road-map-text1 shake-animation' data-aos="fade-up" data-aos-duration="2000" >Interview & Selection</h5>
                                        <h5 className='road-map-text road-map-text2 shake-animation' data-aos="zoom-in" data-aos-duration="2500" >Orientation</h5>
                                        <h5 className='road-map-text road-map-text3 shake-animation' data-aos="fade-left" data-aos-duration="2000" >Market Ready Knowledge</h5>
                                        <h5 className='road-map-text road-map-text4 shake-animation' data-aos="fade-right" data-aos-duration="2500" >Learning & Implementation</h5>
                                        <h5 className='road-map-text road-map-text5 shake-animation' data-aos="fade-left" data-aos-duration="2000" >Doubt Clearing Sessions</h5>
                                        <h5 className='road-map-text road-map-text6 shake-animation' data-aos="fade-right" data-aos-duration="2500">Team Project</h5>
                                        <h5 className='road-map-text road-map-text7 shake-animation' data-aos="fade-left" data-aos-duration="2000" >Special  Sessions</h5>
                                        <h5 className='road-map-text road-map-text8 shake-animation' data-aos="fade-left" data-aos-duration="2500" >Community & Networking</h5>
                                        <h5 className='road-map-text road-map-text9 shake-animation' data-aos="fade-right" data-aos-duration="2000" >Hall of Fame</h5>
                                    </div>
                                    <img data-aos="zoom-in" data-aos-duration="2000" className='road-map-end-img' src={RoadMapEnd} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className='col-12 d-lg-none d-md-none d-block' >
                            <img className='w-100 h-auto' src={require('../../assets/images/mob-road-map.webp')} alt=""></img>
                        </div>
                    </div>
                </div>
            </div>
            <div className='course-syllabus'>
                <div className='hands-on-section py-5'>
                    <div className='container'>
                        <div className='section-handson-sticky overflow-hidden' data-aos="zoom-in" data-aos-duration="1500">
                            <h2 className='section-heading'>Course Syllabus</h2>
                            <h5 className='section-sub-heading mb-5'>What's inside the Course</h5>
                        </div>
                        <div className='row'>
                            <div className='col-lg-3 position-relative'>
                                <div className='hands-on-left-content'>
                                    <ul className='list-unstyled hands-on-content-mob pl-0'>
                                        {/* {['Digital Marketing', 'Sales', 'Video Editing'].map((text, index) => (
                                            <li key={index}>
                                                <span
                                                    className={`hands-on-left-text ${activeTab === index ? 'active' : ''}`}
                                                    onClick={() => handleTabClick(index)}
                                                    ref={el => videoRefs.current[index] = el}
                                                >
                                                    {text}
                                                </span>
                                            </li>
                                        ))} */}
                                        <li>
                                            <span className={`hands-on-left-text active `}>
                                                Digital Marketing
                                            </span>
                                        </li>
                                        {/* <li>
                                            <span className={`hands-on-left-text-disabled `}>
                                                Sales
                                            </span>
                                        </li>
                                        <li>
                                            <span className={`hands-on-left-text-disabled`}>
                                                Video Editing
                                            </span>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                            <div className='col-lg-9'>
                                <div className='hands-on-right-section'>
                                    <div className='row row-margin-bottom'>
                                        <div className='col-lg-6 col-md-7'>
                                            <div className='course-card-content'>
                                                <h5 className='course-sub-heading'>Phase 1:
                                                </h5>
                                                <h5 className='course-sub-heading'>
                                                    Laying Your Foundations</h5>
                                                <p className='course-card-para'>Here, you will learn how to set everything up to get started:<br className='break-tag' /> The Most comprehensive Module on understanding the fundamentals of Marketing.These are more than just modules with straightforward, shallow YouTube-level content. Each one goes into depth on everything you need to do.</p>

                                                <ul className='order-list-course mb-0'>
                                                    <li className='course-card-para'>Fundamentals of Marketing</li>
                                                    <li className='course-card-para'>Marketing Basics</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-5'>
                                            <img className='course-side-img' src={C1} alt="" />
                                        </div>
                                    </div>
                                    <div className='row row-margin-bottom'>
                                        <div className='col-lg-6 col-md-7'>
                                            <div className='course-card-content'>
                                                <h5 className='course-sub-heading'>Phase 2:
                                                </h5>
                                                <h5 className='course-sub-heading'>
                                                    Marketing Mindset
                                                </h5>
                                                <p className='course-card-para'>Here, you will learn how to have the right mindset as an Entrepreneur and as a Marketer. You will be able to understand the opportunities in the market and things you need to do to grab those. </p>

                                                <ul className='order-list-course mb-0'>
                                                    <li className='course-card-para'>Mindset of a Digital Marketer</li>
                                                    <li className='course-card-para'>Introduction of Digital Platforms </li>
                                                    <li className='course-card-para'>Importance of Digital Marketing</li>
                                                    <li className='course-card-para'>Business Objective</li>
                                                    <li className='course-card-para'>Marketing Objective</li>
                                                    <li className='course-card-para'>Digital Marketing Objective</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-5'>
                                            <img className='course-side-img' src={C2} alt="" />
                                        </div>
                                    </div>

                                    <div className='row row-margin-bottom'>
                                        <div className='col-lg-6 col-md-7'>
                                            <div className='course-card-content'>
                                                <h5 className='course-sub-heading'>Phase 3:
                                                </h5>
                                                <h5 className='course-sub-heading'>
                                                    Creatives & Designs
                                                </h5>
                                                <p className='course-card-para'>This Module will give deeper understanding on how important Creatives are and how Design Skills can make you better in Digital Marketing.</p>

                                                <ul className='order-list-course mb-0'>
                                                    <li className='course-card-para'>Design Principles</li>
                                                    <li className='course-card-para'>Understanding CANVA </li>
                                                    <li className='course-card-para'>Understanding Winning Elements</li>
                                                    <li className='course-card-para'>Colour psychology</li>
                                                    <li className='course-card-para'>Understanding Facebook Creatives & Dimensions</li>
                                                    <li className='course-card-para'>Understanding Google Creatives & Dimensions</li>
                                                    <li className='course-card-para'>How to make money using Design Skill eagle</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-5'>
                                            <img className='course-side-img' src={C3} alt="" />
                                        </div>
                                    </div>
                                    <div className='row row-margin-bottom'>
                                        <div className='col-lg-6 col-md-7'>
                                            <div className='course-card-content'>
                                                <h5 className='course-sub-heading'>Phase 4:
                                                </h5>
                                                <h5 className='course-sub-heading'>
                                                    Copywriting
                                                </h5>
                                                <p className='course-card-para'>This Module will teach you how to literally print money through the Art of Writing Text that make people take action. </p>

                                                <ul className='order-list-course mb-0'>
                                                    <li className='course-card-para'>Copywriting</li>
                                                    <li className='course-card-para'>What is Copywriting</li>
                                                    <li className='course-card-para'>Copywriting Methodology</li>
                                                    <li className='course-card-para'>Types of Copywriting</li>
                                                    <li className='course-card-para'>Evergreen Headline Formula</li>
                                                    <li className='course-card-para'> How to make Money using Copywriting Skill</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-5'>
                                            <img className='course-side-img' src={C4} alt="" />
                                        </div>
                                    </div>
                                    <div className='row row-margin-bottom'>
                                        <div className='col-lg-6 col-md-7'>
                                            <div className='course-card-content'>
                                                <h5 className='course-sub-heading'>Phase 5:
                                                </h5>
                                                <h5 className='course-sub-heading'>
                                                    Video Editing
                                                </h5>
                                                <p className='course-card-para'>Here, you will learn the Art of Visual Storytelling using basic to advanced video editing framework that actually works. This is one of the Most In-Demand skills in the current world.</p>

                                                <ul className='order-list-course mb-0'>
                                                    <li className='course-card-para'>Video Creation Techniques</li>
                                                    <li className='course-card-para'>How to make your videos eye-catching</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-5'>
                                            <img className='course-side-img' src={C5} alt="" />
                                        </div>
                                    </div>
                                    <div className='row row-margin-bottom'>
                                        <div className='col-lg-6 col-md-7'>
                                            <div className='course-card-content'>
                                                <h5 className='course-sub-heading'>Phase 6:
                                                </h5>
                                                <h5 className='course-sub-heading'>
                                                    Marketing Mindset
                                                </h5>
                                                <p className='course-card-para'>Here, You will learn how to define a buyer persona on the internet to target them and how to understand customer behaviour in order to target them effectively.</p>

                                                <ul className='order-list-course mb-0'>
                                                    <li className='course-card-para'>Customer Journey (AIDA, UPSYD Model)</li>
                                                    <li className='course-card-para'>Audience and Customer Avatar</li>
                                                    <li className='course-card-para'>Market Research & Tools</li>
                                                    <li className='course-card-para'>Audience Hypothesis</li>
                                                    <li className='course-card-para'>Market Temperature (COLD, WARM, HOT)</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-5'>
                                            <img className='course-side-img' src={C6} alt="" />
                                        </div>
                                    </div>
                                    <div className='row row-margin-bottom'>
                                        <div className='col-lg-6 col-md-7'>
                                            <div className='course-card-content'>
                                                <h5 className='course-sub-heading'>Phase 7:
                                                </h5>
                                                <h5 className='course-sub-heading'>
                                                    Facebook Ads (L1)
                                                </h5>
                                                <p className='course-card-para'>Learn ‘Meta’ as a platform and how to effectively advertise using Meta Platforms.This Module includes everything from Starting a Profile till Launching your Ad Campaigns.</p>

                                                <ul className='order-list-course mb-0'>
                                                    <li className='course-card-para'>Facebook Profile and Pages</li>
                                                    <li className='course-card-para'>Best Practices</li>
                                                    <li className='course-card-para'>Introduction to Meta Ad Manager</li>
                                                    <li className='course-card-para'>Ad account Creation and SOP</li>
                                                    <li className='course-card-para'>Understanding Different Facebook Campaigns</li>
                                                    <li className='course-card-para'>L-1 Lead Generation</li>
                                                    <li className='course-card-para'>Copy, Creative and CTA</li>
                                                    <li className='course-card-para'>Launch your First Ad</li>
                                                    <li className='course-card-para'>Frequent issues in Ads</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-5'>
                                            <img className='course-side-img' src={C7} alt="" />
                                        </div>
                                    </div>
                                    <div className='row row-margin-bottom'>
                                        <div className='col-lg-6 col-md-7'>
                                            <div className='course-card-content'>
                                                <h5 className='course-sub-heading'>Phase 8:
                                                </h5>
                                                <h5 className='course-sub-heading'>
                                                    Automation in “GOD” Mode
                                                </h5>
                                                <p className='course-card-para'>Here, you will learn how to make life simpler and efficient using automations and on-the-go flows to immediately increase your productivity and results. </p>
                                                <ul className='order-list-course mb-0'>
                                                    <li className='course-card-para'>Automation Tools</li>
                                                    <li className='course-card-para'>Email Marketing</li>
                                                    <li className='course-card-para'>How to Save Time & Effort using Automation</li>
                                                    <li className='course-card-para'>Audience Hypothesis</li>
                                                    <li className='course-card-para'>Building your own Automation flow to increase productivity.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-5'>
                                            <img className='course-side-img' src={C8} alt="" />
                                        </div>
                                    </div>
                                    <div className='row row-margin-bottom'>
                                        <div className='col-lg-6 col-md-7'>
                                            <div className='course-card-content'>
                                                <h5 className='course-sub-heading'>Phase 9:
                                                </h5>
                                                <h5 className='course-sub-heading'>
                                                    Funnel Building
                                                </h5>
                                                <p className='course-card-para'>This Module is a game changer in terms of Digital Marketing. This will teach you the WHAT, WHY & HOW about Funnels and how to leverage the state of the art technology to target people effectively and generate high quality leads.</p>

                                                <ul className='order-list-course mb-0'>
                                                    <li className='course-card-para'>Different types of Funnels & Landing Pages</li>
                                                    <li className='course-card-para'>Copy for Landing Page</li>
                                                    <li className='course-card-para'>Integrating Payment Systems</li>
                                                    <li className='course-card-para'>Making Money as a Funnel Builder</li>
                                                    <li className='course-card-para'>How to build your Own Sales Funnel</li>
                                                    <li className='course-card-para'>How to leverage Sales Funnels for Better Marketing and Customer Experience.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-5'>
                                            <img className='course-side-img' src={C9} alt="" />
                                        </div>
                                    </div>
                                    <div className='row row-margin-bottom'>
                                        <div className='col-lg-6 col-md-7'>
                                            <div className='course-card-content'>
                                                <h5 className='course-sub-heading'>Phase 10:
                                                </h5>
                                                <h5 className='course-sub-heading'>
                                                    Facebook Ads (L2)
                                                </h5>
                                                <p className='course-card-para'>In this Module, you will learn the Art of Conversions Campaigns. The psychology and the strategy behind making people purchase without even talking to them.</p>
                                                <ul className='order-list-course mb-0'>
                                                    <li className='course-card-para'>Facebook Campaigns</li>
                                                    <li className='course-card-para'>L-2 Conversion Campaigns</li>
                                                    <li className='course-card-para'>Pixel Integration</li>
                                                    <li className='course-card-para'>Brand Safety Guidelines</li>
                                                    <li className='course-card-para'>Metrics and Measurements</li>
                                                    <li className='course-card-para'>E-Commerce Ads and Shopify</li>
                                                    <li className='course-card-para'>Understanding D2C</li>
                                                    <li className='course-card-para'>Catalogue and Commerce Manager</li>
                                                    <li className='course-card-para'>Successful Case Studies</li>
                                                    <li className='course-card-para'>Retargeting Ads</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-5'>
                                            <img className='course-side-img' src={C10} alt="" />
                                        </div>
                                    </div>
                                    <div className='row row-margin-bottom'>
                                        <div className='col-lg-6 col-md-7'>
                                            <div className='course-card-content'>
                                                <h5 className='course-sub-heading'>Phase 11:
                                                </h5>
                                                <h5 className='course-sub-heading'>
                                                    Artificial Intelligence in Marketing
                                                </h5>
                                                <p className='course-card-para'>This module is one of the most interesting and application-oriented approach will help you use Artificial Intelligence to your advantage during marketing.</p>
                                                <ul className='order-list-course mb-0'>
                                                    <li className='course-card-para'>Introduction to AI</li>
                                                    <li className='course-card-para'>AI in Marketing</li>
                                                    <li className='course-card-para'>AI tools that make Marketing Easy</li>
                                                    <li className='course-card-para'>How to use AI in your Daily Marketing Activities</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-5'>
                                            <img className='course-side-img' src={C11} alt="" />
                                        </div>
                                    </div>
                                    <div className='row row-margin-bottom'>
                                        <div className='col-lg-6 col-md-7'>
                                            <div className='course-card-content'>
                                                <h5 className='course-sub-heading'>Phase 12:
                                                </h5>
                                                <h5 className='course-sub-heading'>
                                                    Influence through LinkedIn
                                                </h5>
                                                <p className='course-card-para'>Here, you will learn how to use LinkedIn for better marketing and also to build AUTHORITY in any field. This Module is a comprehensive one as this alone can help you connect with the most qualified high-ticket customers. </p>
                                                <ul className='unorder-list-course mb-0'>
                                                    <li className='course-card-para'>LinkedIn Sales Navigator</li>
                                                    <li className='course-card-para'>How to Build Authority using LinkedIn</li>
                                                    <li className='course-card-para'>How to Create Content that Converts in LinkedIn</li>
                                                    <li className='course-card-para'>PERSONAL BRANDING</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-5'>
                                            <img className='course-side-img' src={C12} alt="" />
                                        </div>
                                    </div>

                                    <div className='row row-margin-bottom'>
                                        <div className='col-lg-6 col-md-7'>
                                            <div className='course-card-content'>
                                                <h5 className='course-sub-heading'>Phase 13:
                                                </h5>
                                                <h5 className='course-sub-heading'>
                                                    Mastering Google Ads
                                                </h5>
                                                <p className='course-card-para'>Here, you will learn the effective methods of Search Engine Marketing and PPC. This also helps you understand how to use the world’s biggest search engine for better marketing purposes.</p>
                                                <p className='course-card-para font-bold mb-2'>Video Campaign</p>
                                                <ul className='unorder-list-course mb-3'>
                                                    <li className='course-card-para'>Understanding the benefits of Video Advertising</li>
                                                    <li className='course-card-para'>Creating and optimizing Video Ads for YouTube campaigns</li>
                                                </ul>
                                                <p className='course-card-para font-bold mb-2'>Shopping Campaign</p>
                                                <ul className='unorder-list-course mb-0'>
                                                    <li className='course-card-para'>Setting up a Google Merchant Center account</li>
                                                    <li className='course-card-para'>Creating Product Listings and optimizing Product Feeds for Shopping Campaigns</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-5'>
                                            <img className='course-side-img' src={C13} alt="" />
                                        </div>
                                    </div>
                                    <div className='row row-margin-bottom'>
                                        <div className='col-lg-6 col-md-7'>
                                            <div className='course-card-content'>
                                                <h5 className='course-sub-heading'>Phase 14:
                                                </h5>
                                                <h5 className='course-sub-heading'>
                                                    SEO
                                                </h5>
                                                <p className='course-card-para'>Here, you will learn the effective methods of Search Engine Optimization. You will also learn the Art of making any website/ brand/ business on the internet to rank higher in their niche.
                                                </p>
                                                <ul className='unorder-list-course mb-0'>
                                                    <li className='course-card-para'>Basics of SEO & Role of SEO in Digital Marketing</li>
                                                    <li className='course-card-para'>Why Should a Business Invest in SEO</li>
                                                    <li className='course-card-para'>On Page SEO</li>
                                                    <li className='course-card-para'>Technical SEO</li>
                                                    <li className='course-card-para'>Local SEO</li>
                                                    <li className='course-card-para'>Off Page SEO</li>
                                                    <li className='course-card-para'>Competitor Analysis</li>
                                                    <li className='course-card-para'>SEO Audit</li>
                                                    <li className='course-card-para'>Data Analysis in SEO</li>
                                                    <li className='course-card-para'>Conversion Rate Optimisation</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-5'>
                                            <img className='course-side-img' src={C14} alt="" />
                                        </div>
                                    </div>
                                    <div className='row row-margin-bottom'>
                                        <div className='col-lg-6 col-md-7'>
                                            <div className='course-card-content'>
                                                <h5 className='course-sub-heading'>Phase 15:
                                                </h5>
                                                <h5 className='course-sub-heading'>
                                                    Mastering Content Creation
                                                </h5>
                                                <p className='course-card-para'>Mastering this module can help you earn the most expensive currency (ie: ATTENTION) from anyone on the Internet using Content.</p>
                                               
                                                <ul className='unorder-list-course mb-0'>
                                                    <li className='course-card-para'>Planning your Content</li>
                                                    <li className='course-card-para'>The 10 step Formula for Instagram</li>
                                                    <li className='course-card-para'>Instagram as your Lead and Conversion Source</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-5'>
                                            <img className='course-side-img' src={C15} alt="" />
                                        </div>
                                    </div>
                                    <div className='row row-margin-bottom'>
                                        <div className='col-lg-6 col-md-7'>
                                            <div className='course-card-content'>
                                                <h5 className='course-sub-heading'>Phase 16:
                                                </h5>
                                                <h5 className='course-sub-heading'>
                                                    Persuasive Selling
                                                </h5>
                                                <p className='course-card-para'>Here, you will learn the Art of Ethical Selling, where the skill sets such as Effective Selling and Persuasive Communication will be taught. Learning this one skill along with the Digital Marketing Strategies will help you land high-ticket clients effortlessly.

                                                </p>
                       
                                                <ul className='unorder-list-course mb-0'>
                                                    <li className='course-card-para'>LIPS Framework</li>
                                                    <li className='course-card-para'>RASNA Model</li>
                                                    <li className='course-card-para'>How to sell to anyone</li>
                                                    <li className='course-card-para'>Effective Communication</li>
                                                    <li className='course-card-para'>Positioning your Product & Service</li>
                                                    <li className='course-card-para'>Becoming a Solution Provider</li>

                                                </ul>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-5'>
                                            <img className='course-side-img' src={C16} alt="" />
                                        </div>
                                    </div>
                                    <div className='row row-margin-bottom'>
                                        <div className='col-lg-6 col-md-7'>
                                            <div className='course-card-content'>
                                                <h5 className='course-sub-heading'>Phase 17:
                                                </h5>
                                                <h5 className='course-sub-heading'>
                                                    Capstone Project
                                                </h5>
                                                <p className='course-card-para mb-0'>The Capstone Project is an integral part of this program.  It can take many different structures; however, its purpose remains the same. It is an unique chance to do research & to devise an innovative answer for a real-world issue. It may involve Media Planning, Campaign Structure, Creative Design and more.</p>

                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-5'>
                                            <img className='course-side-img' src={C17} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='apply-for-course overflow-hidden py-5'>
                    <div className='container'>
                        <div className='row justify-content-center'>
                            <div className='col-lg-10'>
                                <h2 className='section-heading fs-2 mb-4' data-aos="fade-right" data-aos-duration="1500">Apply for the Course</h2>
                                <h5 className='section-sub-heading fs-5 mb-5' data-aos="fade-right" data-aos-duration="1500">Get the basics right. Enter the industry with confidence. <br /> <span className="eagle-award-gradient fs-5">Speak with our Expert.</span> </h5>
                                <div className='position-relative'>
                                    <div className='apply-course-card' data-aos="zoom-in" data-aos-duration="1500">
                                        <div className='container'>
                                            <div className='row justify-content-between align-items-center'>
                                                <div className="col-lg-8">
                                                    <div className='row'>
                                                        <div className='col-lg-4 col-md-4 mt-lg-0 mt-md-0 mt-4'>
                                                            <div className='mb-lg-0 mb-md-0 mb-2'>
                                                                <p className='apply-course-text  mb-lg-4 mb-md-4 mb-2'>Upcoming Cohort :</p>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-8 col-md-4 '>
                                                            <div className='mb-lg-0 mb-md-0 mb-4'>
                                                                <h6 className='apply-course-heading mb-lg-4 mb-md-4 mb-2'>September 2024</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-lg-4 col-md-4 mt-lg-0 mt-md-0 mt-2'>
                                                            <div className='mb-lg-0 mb-md-0 mb-2'>
                                                                <p className='apply-course-text mb-0'>Mode :</p>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-8 col-md-4 '>
                                                            <div className='mb-lg-0 mb-md-0 mb-4'>
                                                                <h6 className='apply-course-heading mb-0'>Blended Model (Online + Offline)</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4  col-md-4'>
                                                    <div className='mb-lg-0 mb-md-0 mb-4'>
                                                        <button type="button" class="btn btn-book-expert-call mb-2 w-75">Join Now</button>
                                                        <p className='apply-course-text mb-0'>(Only Limited Students per cohort)</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <h5 className='upcoming-batch '>Upcoming Batch</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default CourseContent