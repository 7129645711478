import "./App.css";
import "../src/Css/Style.css";
import "../src/Css/Responsive.css";
import Main from "./Main";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import ExitIntentPopup from "./Components/Model/ExitIntentPopup";

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (e.clientY < 5) {
        setIsVisible(true);
      }
    };

    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div className="App">
      <Main />
      <ExitIntentPopup
        open={isVisible}
        setOpen={() => {
          setIsVisible(false);
        }}
      />

    </div>
  );
}

export default App;
