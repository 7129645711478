import React, { useEffect, useState } from "react";
import Logo from "../assets/images/logo.webp";
import { NavLink } from "react-router-dom";
import SpeakWithUs from "./Model/SpeakWithUs";
const Navbar = () => {
  const [speakWithUsModal, setspeakWithUsModal] = useState(false);

  const [navSize, setnavSize] = useState("5rem");
  const [navColor, setnavColor] = useState("transparent");
  const listenScrollEvent = () => {
    window.scrollY > 10 ? setnavColor("#000") : setnavColor("transparent");
    window.scrollY > 10 ? setnavSize("4rem") : setnavSize("5rem");
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  const [isNavbarVisible, setNavbarVisible] = useState(true);
  const handleButtonClick = () => {
    setNavbarVisible(false);
    setspeakWithUsModal(true);
  };


  return (
    <div>
      {isNavbarVisible && (
        <nav style={{
          backgroundColor: navColor,
          transition: "all 1s",
        }} className="navbar navbar-dark navbar-expand-lg py-3">
          <div className="container">
            <a className="navbar-brand" href="/">
              <img className="header-logo" src={Logo} alt=""></img>
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <NavLink className="nav-link " to={"/AboutUs"}>
                    About Us
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link " to={"/Course"}>
                    Course
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to={"/Experts"}>
                    Experts
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to={"/Results"}>
                    Results
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to={"/Events"} >
                    Events
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to={"/Blogs"}>
                    Blogs
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link " to={"/ContactUs"}>
                    Contact Us
                  </NavLink>
                </li>
                <li className="nav-item">
                  <button
                    onClick={handleButtonClick}
                    type="button"
                    class="btn btn-speak-with-us"
                  >
                    Speak with Us
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      )}
      <SpeakWithUs
        open={speakWithUsModal}
        setOpen={() => {
          setspeakWithUsModal(false);
          setNavbarVisible(true);
        }}
      />
    </div>
  );
};

export default Navbar;
