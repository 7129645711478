import React, { useEffect } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
// import BrochureDownloadModal from "./BroucherDownloadModal";

const TermsndCondition = () => {
  useEffect(() => {
    window.scroll(0, 0);
}, []);
  return (
    <div className="outer-pages">
      <Navbar />
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div
              title="Page 1"
              style={{
                textAlign: "left",
                paddingTop: "120px",
                paddingBottom: "60px",
              }}
            >
              <div>
                <div>
                  <p>
                    <strong>
                      <span style={{ fontSize: "24pt" }}>
                        Terms &amp; Conditions&nbsp;
                      </span>
                    </strong>
                  </p>
                  <p>
                    <span style={{ fontSize: "12pt" }}>
                      These Terms and Conditions, along with privacy policy or
                      other terms (&ldquo;Terms&rdquo;) constitute a binding
                      agreement by and between SOCIAL EAGLE PRIVATE LIMITED, (
                      &ldquo;Website Owner&rdquo; or &ldquo;we&rdquo; or
                      &ldquo;us&rdquo; or &ldquo;our&rdquo;) and you
                      (&ldquo;you&rdquo; or &ldquo;your&rdquo;) and relate to
                      your use of our website, goods (as applicable) or services
                      (as applicable) (collectively, &ldquo;Services&rdquo;).
                    </span>
                  </p>
                  <p>
                    <span style={{ fontSize: "12pt" }}>
                      By using our website and availing the Services, you agree
                      that you have read and accepted these Terms (including the
                      Privacy Policy). We reserve the right to modify these
                      Terms at any time and without assigning any reason. It is
                      your responsibility to periodically review these Terms to
                      stay informed of updates.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontSize: "12pt" }}>
                      <b>
                        The use of this website or availing of our Services is
                        subject to the following terms of use:
                      </b>
                    </span>
                  </p>
                  <ul>
                    <li>
                      <p>
                        <span style={{ fontSize: "12pt" }}>
                          To access and use the Services, you agree to provide
                          true, accurate and complete information to us during
                          and after registration, and you shall be responsible
                          for all acts done through the use of your registered
                          account.
                        </span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style={{ fontSize: "12pt" }}>
                          Neither we nor any third parties provide any warranty
                          or guarantee as to the accuracy, timeliness,
                          performance, completeness or suitability of the
                          information and materials offered on this website or
                          through the Services, for any specific purpose. You
                          acknowledge that such information and materials may
                          contain inaccuracies or errors and we expressly
                          exclude liability for any such inaccuracies or errors
                          to the fullest extent permitted by law.
                        </span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style={{ fontSize: "12pt" }}>
                          Your use of our Services and the websiteis solely at
                          your own risk and discretion.. You are required to
                          independently assess and ensure that the Services meet
                          your requirements.
                        </span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style={{ fontSize: "12pt" }}>
                          The contents of the Website and the Services are
                          proprietary to Us and you will not have any authority
                          to claim any intellectual property rights, title, or
                          interest in its contents.
                        </span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style={{ fontSize: "12pt" }}>
                          You acknowledge that unauthorized use of the Website
                          or the Services may lead to action against you as per
                          these Terms or applicable laws.
                          <br />
                          You agree to pay us the charges associated with
                          availing the Services.
                        </span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style={{ fontSize: "12pt" }}>
                          You agree not to use the website and/ or Services for
                          any purpose that is unlawful, illegal or forbidden by
                          these Terms, or Indian or local laws that might apply
                          to you.
                        </span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style={{ fontSize: "12pt" }}>
                          You agree and acknowledge that website and the
                          Services may contain links to other third party
                          websites. On accessing these links, you will be
                          governed by the terms of use, privacy policy and such
                          other policies of such third party websites.
                        </span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style={{ fontSize: "12pt" }}>
                          You understand that upon initiating a transaction for
                          availing the Services you are entering into a legally
                          binding and enforceable contract with the us for the
                          Services.
                        </span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style={{ fontSize: "12pt" }}>
                          You shall be entitled to claim a refund of the payment
                          made by you in case we are not able to provide the
                          Service. The timelines for such return and refund will
                          be according to the specific Service you have availed
                          or within the time period provided in our policies (as
                          applicable). In case you do not raise a refund claim
                          within the stipulated time, than this would make you
                          ineligible for a refund.
                        </span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style={{ fontSize: "12pt" }}>
                          Notwithstanding anything contained in these Terms, the
                          parties shall not be liable for any failure to perform
                          an obligation under these Terms if performance is
                          prevented or delayed by a force majeure event.
                        </span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style={{ fontSize: "12pt" }}>
                          These Terms and any dispute or claim relating to it,
                          or its enforceability, shall be governed by and
                          construed in accordance with the laws of India.
                        </span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style={{ fontSize: "12pt" }}>
                          All disputes arising out of or in connection with
                          these Terms shall be subject to the exclusive
                          jurisdiction of the courts in PERUMBAKKAM, Tamil Nadu.
                        </span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style={{ fontSize: "12pt" }}>
                          All concerns or communications relating to these Terms
                          must be communicated to us using the contact
                          information provided on this website.
                        </span>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsndCondition;
