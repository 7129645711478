import React, { useEffect } from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import CourseBanner from '../Components/Course/CourseBanner'
import CourseContent from '../Components/Course/CourseContent'
import BannerBottomCarousel from '../Components/BannerBottomCarousel'

const Course = () => {
    useEffect(() => {
        window.scroll(0, 0);
    }, []);
    return (
        <div>
            <Navbar />
            <CourseBanner />
            <BannerBottomCarousel />
            <CourseContent />
            <Footer />
        </div>
    )
}

export default Course