import React from "react";
import exportbanimg from "../../assets/images/Expertbannerimg.webp";

const ExpertsBanner = () => {
  return (
    <div className="expert-banner position-relative">
      <div className="expertbanner-content" style={{ position: "relative" }}>
        <h1
          className="banner-heading mb-4"
          data-aos="zoom-in"
          data-aos-duration="1000"
        >
          Know our Digital Experts
        </h1>
        <h5
          className="banner-sub-heading mb-5"
          data-aos="zoom-in"
          data-aos-duration="1000"
        >
          Who Practice what they Teach everyday
        </h5>
      </div>

      <img src={exportbanimg} alt="../" className="expertimgban" />

    </div>
  );
};

export default ExpertsBanner;
