import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import AboutUs from "./Pages/AboutUs";
import Results from "./Pages/Results";
import ContactUs from "./Pages/ContactUs";
import Course from "./Pages/Course";
import Experts from "./Pages/Experts";
import TermsndCondition from "./Pages/TermsndCondition";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import RefundPolicy from "./Pages/RefundPolicy";
import Blogs from "./Pages/Blogs";
import Events from "./Pages/Events";

const Main = () => {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Aboutus" element={<AboutUs />} />
        <Route path="/Results" element={<Results />} />
        <Route path="/ContactUs" element={<ContactUs />} />
        <Route path="/Course" element={<Course />} />
        <Route path="/Experts" element={<Experts />} />
        <Route path="/Events" element={<Events />} />
        <Route path="/Blogs" element={<Blogs />} />
        <Route path="/TermsandConditions" element={<TermsndCondition />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/RefundandCancellationPolicy" element={<RefundPolicy />} />
      </Routes>
    </HashRouter>
  );
};

export default Main;
