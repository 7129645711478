import React from 'react'

const EventsBanner = () => {
    return (
        <div className="about-banner">
            <div className="mt-lg-0 mt-md-0 mt-5 ">
                <h1
                    className="banner-heading mb-4"
                    data-aos="zoom-in"
                    data-aos-duration="1500"
                >
                    Events
                </h1>
                <h5
                    className="banner-sub-heading"
                    data-aos="zoom-in"
                    data-aos-duration="1500"
                >
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </h5>
            </div>
        </div>
    )
}

export default EventsBanner