import React, { useEffect } from 'react'
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import BlogsBanner from '../Components/Blogs/BlogsBanner';
import BlogsContent from '../Components/Blogs/BlogsContent';

const Blogs = () => {
    useEffect(() => {
        window.scroll(0, 0);
    }, []);
    return (
        <div>
            <Navbar />
            <BlogsBanner />
            <BlogsContent />
            <Footer />
        </div>
    )
}

export default Blogs