import React, { useState, useRef, useEffect } from 'react';
import '@wistia/wistia-player';
import VideoThumbnail from '../../assets/images/home-video-thumbnail.webp';
import PlayIcon from '../../assets/images/play-icon.webp';
import PlayIconTesti from '../../assets/images/play-icon-testi.webp';
import DownArrow from '../../assets/images/arrow-down.webp';
import HomeAward from '../../assets/images/home-award.webp';
import TeamHome from '../../assets/images/team-home.webp';
import Favicon from '../../assets/images/logo-fav.webp';
import Testi1 from '../../assets/images/testi1.webp';
import Testi2 from '../../assets/images/testi2.webp';
import Testi3 from '../../assets/images/testi3.webp';
import Testi4 from '../../assets/images/testi4.webp';
import Testi5 from '../../assets/images/testi5.webp';
import Testi6 from '../../assets/images/testi6.webp';
import Testi7 from '../../assets/images/testi7.webp';
import Testi8 from '../../assets/images/testi8.webp';
import Testi9 from '../../assets/images/testi9.webp';
import Testi10 from '../../assets/images/testi10.webp';
import Testi11 from '../../assets/images/testi11.webp';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import CustomModal from '../Model/CustomModal';
export const HomeContent = () => {
    const [openVideo, setOpenVideo] = useState(false);
    const [currentMediaId, setCurrentMediaId] = useState(null);

    const [isPlaying, setIsPlaying] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const videoRefs = useRef([]);
    const observerRef = useRef(null);

    // Set up Intersection Observer to detect which video is in view
    useEffect(() => {
        if (observerRef.current) observerRef.current.disconnect();

        observerRef.current = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const index = videoRefs.current.indexOf(entry.target);
                    if (index !== -1) {
                        setActiveTab(index);
                    }
                }
            });
        }, { threshold: 0.5 }); // Adjust threshold as needed

        videoRefs.current.forEach(video => {
            if (video) observerRef.current.observe(video);
        });

        return () => {
            if (observerRef.current) observerRef.current.disconnect();
        };
    }, []);

    const handleTabClick = (index) => {
        setActiveTab(index);
        if (videoRefs.current[index]) {
            const offsetTop = videoRefs.current[index].getBoundingClientRect().top + window.pageYOffset - 100; // Adjust -100 to your desired offset
            window.scrollTo({
                top: offsetTop,
                behavior: 'smooth',
            });
        }
    };

    const location = useLocation();
    const navigation = useNavigate();
    useEffect(() => {
        const { search } = location;
        const containerId = search.substring(4); // Remove the leading slash
        const element = document.getElementById(containerId);

        if (element) {
            element.scrollIntoView({
                behavior: "smooth",
                inline: "center",
                block: "center",
            });
        }
    }, [location]);

    const handsOnContent = [
        {
            mediaId: "cdrbmupecx",
            mediaPara: "Blended Model with a Practical and End-to-End Training Approach",
        },
        {
            mediaId: "onxfyjwgl6",
            mediaPara: "Taught by Who’s Who of the Industry, Complete Experts in their Domain Experience",
        },
        {
            mediaId: "78cmi5vrkw",
            mediaPara: "Complete Training from understanding the business to getting & working with clients and scaling the business",
        },
        {
            mediaId: "gnr0eeig7g",
            mediaPara: "Dedicated Guidance through Action-Oriented Implementable Frameworks, along with peer-to-peer communication and support",
        },
        {
            mediaId: "k5t6pqrogx",
            mediaPara: "Tested Knowledge & Market-Ready Training which can be implemented right away",
        },
        {
            mediaId: "khrd0mmti8",
            mediaPara: "Data-Driven, Customer-Centric, Agency-Based, Performance-Oriented Strategies & Training",
        },
        {
            mediaId: "mztjxl0bsh",
            mediaPara: "A Community of Positive like-minded individuals where one can collaborate, hire, outsource and build together with a win-win mindset",
        },
        {
            mediaId: "k6udl6kag8",
            mediaPara: "Continuous & Extended Knowledge Transfer even after course completion so students can be self-reliant",
        },
        {
            mediaId: "whlsr6kkmp",
            mediaPara: "Helping Members visualise the bigger opportunities through showing real-world scenarios",
        },
        {
            mediaId: "jvoku2s6zj",
            mediaPara: "Emphasis on Entrepreneurship Training as success lies in understanding the Business fully and totally",
        },
    ]

    const videos = [
        {
            thumbImg: require('../../assets/images/testi1.webp'),
            mediaId: "tsg3172b6r",
            heading: "How Mukesh bought his first car in 90 days Through Freelancing",
            para: "Having spent more than 5L rupees in various other courses, Mukesh was not able to find the depth of digital marketing until he found the right knowledge and mentors at Social Eagle",
        },
        {
            thumbImg: require('../../assets/images/testi2.webp'),
            mediaId: "z9bgdbzncd",
            heading: "How Ramesh was able to get 4 New Clients Every Month for his agency services.",
            para: "Ramesh quickly shifted from being a professor to owning an agency within just 40 days of starting to learn Digital Marketing. Mastering everything from strategy creation to high-ticket sales boosted his confidence, securing him four clients monthly. He's now expanding his team.",
        },
        {
            thumbImg: require('../../assets/images/testi3.webp'),
            mediaId: "80z8jx9epp",
            heading: "How Jeyasudha was able to earn 6+ Lakhs as an housewife.",
            para: "Jeyasudha, a former employee on a career break post-marriage, sought work-from-home opportunities. Discovering Social Eagle Academy's Digital Entrepreneurship Initiator Course, she secured her first client in under 30 days and now serves over 5 clients, earning over 6 lakh in revenue within 3 months.",
        },
        {
            thumbImg: require('../../assets/images/testi4.webp'),
            mediaId: "nwxcyuuvfw",
            heading: "How Naveen was able to make More than 7 Lakhs as an agency owner after failing in his 3 other businesses.",
            para: "Naveen, a first-time entrepreneur and mechanical engineer, had several failed businesses before joining Social Eagle. Now, after completing the Digital Entrepreneurship Initiator Program, he successfully runs a five-figure agency alongside his engineering career.",
        },
        {
            thumbImg: require('../../assets/images/testi5.webp'),
            mediaId: "mjxnm5v1z9",
            heading: "How a Corporate Employee like Priya was able to make more  than 5 Lakh with her side business using Digital Marketing",
            para: "Priya, originally an employee, joined Social Eagle Academy to learn digital marketing and assist her company. Discovering her passion, she began side hustles alongside her job, earning over 5 lakhs in five months.",
        },
        {
            thumbImg: require('../../assets/images/testi6.webp'),
            mediaId: "9rwle03cb0",
            heading: "How Ganesh was able to make 26 Lakh in Profit with 1000 Rupees.",
            para: "Ganesh, a real estate business owner frustrated with unreliable agencies, self-learned digital marketing but struggled with the terminology. Joining Social Eagle Academy resolved his doubts in a month. He ran successful ads, earning 26 lakhs from one sale. He then launched his agency and became a digital strategist.",
        },
        {
            thumbImg: require('../../assets/images/testi7.webp'),
            mediaId: "t9kj0hkvg6",
            heading: "How Jamuna is making a profit of over 1 Lakh rupees for her Gift Shop Business.",
            para: "Jamuna was struggling to find a way to promote her offline gift shop in online. She tried multiple ways which she found was not helpful until she joined the Digital Entrepreneurship Initiator Program at Social Eagle.",
        },
        {
            thumbImg: require('../../assets/images/testi8.webp'),
            mediaId: "p19bytz64q",
            heading: "How Giriesh helped his client make over $3000 with an investment of 1000 rupees.",
            para: "Having spent more than 5L rupees in various other courses, Mukesh was not able to find the depth of digital marketing until he found the right knowledge and mentors at Social Eagle",
        },
        {
            thumbImg: require('../../assets/images/testi9.webp'),
            mediaId: "d6jzrvsx48",
            heading: "How Vinothini was able to grow her offline Boutique’s sales by 3X during off-season. ",
            para: "Vinothini runs a boutique for kids party wear online and offline, a unique niche. After taking multiple courses with mixed results, she found success with mentorship from Social Eagle Academy. Implementing her new knowledge, she received over 1000 inquiries in the first week and made sales exceeding 8 lakhs within 30 days.",
        },
        {
            thumbImg: require('../../assets/images/testi10.webp'),
            mediaId: "jaiza2qyw4",
            heading: "Richerd Made over 23 Lakh and also increased this Team Size by 3X.",
            para: "Richard, a digital marketer and business owner, faced financial struggles and lost confidence. Joining Social Eagle’s program connected him with supportive peers, boosting his motivation. He earned over 20 lakhs and won the Eagle Award at the TN Digital Summit.",
        },
        {
            thumbImg: require('../../assets/images/testi11.webp'),
            mediaId: "zi2h2hlu0y",
            heading: "How Kamal turned into an Entrepreneur from an Employee while making more than 20 Lakhs in revenue.",
            para: "Kamal was a Corporate Employee and was helping his wife’s small business. He wanted to learn Digital Marketing to help his wife and in the process he started his own agency. He built Social Guru and now it is one of the renowned agencies in Dindigul, Tamil Nadu.",
        },
        {
            thumbImg: require('../../assets/images/testi12.webp'),
            mediaId: "wxhwkpeskn",
            heading: "How Mark Marimuthu Made Over 20 Lakhs by Integrating Digital Marketing into His Existing Business.",
            para: "Mark Marimuthu runs a traditional media business. He wanted to add digital marketing services, so he joined Social Eagle’s Mentorship Program. By upselling digital marketing to his clients and offering combined services, he earned over 20 lakhs in less than 5 months.",
        },
        {
            thumbImg: require('../../assets/images/testi13.webp'),
            mediaId: "xagsewhadg",
            heading: "How Ameenur Made Over 12 Lakhs in Revenue from Part-time Work in His Business",
            para: "Ameenur, a full-time employee in Germany, runs two part-time businesses. After struggling despite joining many courses, he joined Social Eagle’s Digital Entrepreneurship Program, found the missing link, and now earns 2-3 lakhs every two weeks through webinars.",
        },
        {
            thumbImg: require('../../assets/images/testi14.webp'),
            mediaId: "k7msiy3rrb",
            heading: "How Sunitha Made Over 5 Lakhs in 60 Days",
            para: "Sunitha, a PhD scholar and healthcare consultant, was about to join a year-long digital marketing course. Instead, she joined Social Eagle after learning about Dharaneetharan and his team. Implementing what she learned and earning additional certifications, she became a LinkedIn Personal Branding Expert. Serving clients, she made over 5 lakhs in less than 60 days.",
        },
        {
            thumbImg: require('../../assets/images/testi15.webp'),
            mediaId: "5weai30rkn",
            heading: "How Alex Vibin Grew His E-Commerce Brand and Started Two Other Businesses, Earning Over 5 Lakhs",
            para: "Alex Vibin, founder of Noobie Kid, an e-commerce brand selling kids toys, struggled with consistent sales through organic content. After joining the Digital Entrepreneurship Program, he started running ads, doubling his sales predictably. He also started a digital marketing agency and a tourism company, leveraging digital marketing to succeed.",
        },
        {
            thumbImg: require('../../assets/images/testi16.webp'),
            mediaId: "h16jza2k15",
            heading: "How Kannan Increased His Client Base by 55% in less than 20 Days and is earning 4L/month",
            para: "Kannan, founder of Growpix, a digital marketing agency, wanted to grow his business beyond 10 clients. After joining the Digital Entrepreneurship Program, he helped clients earn over 35 lakhs in 40 days. This success led to many referrals, increasing his client base by 55% in less than 20 days.",
        },
        {
            thumbImg: require('../../assets/images/testi17.webp'),
            mediaId: "lju3ufacoo",
            heading: "How Anitha Made Over 5 Lakhs with Digital Marketing",
            para: "Anitha, a housewife living in Dubai, began learning digital marketing to help her husband's business. After a 30-day course, she started her own content marketing agency, helping brands build authority on LinkedIn. Now, she earns over 5 lakhs and chooses her clients.",
        },
        {
            thumbImg: require('../../assets/images/testi18.webp'),
            mediaId: "yuonw7a0su",
            heading: "How Ram Kumar Landed Big Clients billing Over 7 Lakhs",
            para: "Ram Kumar wanted to leave his current job. After joining Social Eagle Academy’s Mentorship Program and applying what he learned, he secured a major client deal worth over 7 lakhs in less than 30 days. Now, he is happily working as a digital entrepreneur.",
        },
        {
            thumbImg: require('../../assets/images/testi19.webp'),
            mediaId: "asoipzigg3",
            heading: "How Muthu Kumar added Digital Marketing to His Consulting Business and Earns Over 1 Lakh Each Month",
            para: "Muthu Kumar runs a manufacturing consulting business, training people on processes and sales. After mentorship from Social Eagle Academy, he added digital marketing services to his offerings. Now, he earns an extra 1 lakh every month alongside his consulting business.",
        },
        {
            thumbImg: require('../../assets/images/testi20.webp'),
            mediaId: "4l6znlv60g",
            heading: "How Sanjay Earns an Extra 1 Lakh as a Freelancer While Working Full-Time",
            para: "Sanjay is a digital marketing executive. His full-time job doesn't pay enough. He joined to earn an extra 1 lakh rupees as a freelancer. By using the Community's support, he reached his goal in less than 20 days after joining the Digital Entrepreneurship Program.",
        },
        {
            thumbImg: require('../../assets/images/testi21.webp'),
            mediaId: "2ofw3e9nox",
            heading: "How Maheswari Made Over 16 Lakhs in Revenue by Leveraging Digital Marketing in 3 Months",
            para: "Maheswari runs Osai Media, a media company. Despite having her own agency, she wanted to upskill and help her clients transition to digital marketing. After joining Social Eagle’s Mentorship Program, she started upselling digital services and onboarding new clients. Using her new skills in other businesses, she comfortably made over 16 lakhs in 3 months.",
        },

    ]
    return (
        <div className='home-content-section'>
            <div className='home-video-section  overflow-hidden py-5'>
                <div className='container pt-5 '>
                    <div className='row justify-content-between'>
                        <div className='col-lg-8' data-aos="fade-up-right" data-aos-duration="1500">
                            <div className='position-relative'>
                                {!isPlaying ? (
                                    <div onClick={() => setIsPlaying(true)} style={{ cursor: 'pointer' }}>
                                        <img src={VideoThumbnail} alt="Thumbnail" style={{ width: '100%', height: '100%' }} />
                                        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                            <img className='play-icon-video ' src={PlayIcon} alt=""></img>
                                        </div>
                                    </div>
                                ) : (
                                    <wistia-player
                                        style={{
                                            width: '100%',
                                            height: 'auto',
                                            borderRadius: '12px'
                                        }}
                                        media-id="h0i55nsez3"
                                        player-color="#D32B3A"
                                    ></wistia-player>)}
                            </div>

                        </div>
                        <div className='col-lg-3' data-aos="fade-up-left" data-aos-duration="1500">
                            <div >
                                <p className='home-video-para'><span className='span-red-content'>
                                    Tamil Nadu’s only Digital Entrepreneurship Program&nbsp;</span><br className='break-tag' />
                                    Focused on Data-Driven,<br className='break-tag' /> Performance-Based,<br className='break-tag' /> Customer-Obsessed,
                                    and<br className='break-tag' /> Result-Oriented learning<br className='break-tag' /> models.</p>
                                <div className='position-relative'>
                                    <NavLink to={"/?id=home-below-section"} class="circle-div">
                                        <div class="circle-center-link">
                                            <div class="circle-center">
                                                <img className='down-arrow-rotate' src={DownArrow} alt="Tick" />
                                            </div>
                                            <div id="circle" class="rotate">
                                                <svg width="300px" height="300px" viewBox="0 0 300 300">
                                                    <defs>
                                                        <path id="circlePath" d="M 150, 150 m -60, 0 a 60,60 0 0,1 120,0 a 60,60 0 0,1 -120,0 "></path>
                                                    </defs>
                                                    <circle cx="150" cy="150" r="60" fill="none"></circle>
                                                    <g>
                                                        <use xlinkHref="#circlePath" fill="none"></use>
                                                        <text fill="#fff" font-size="11.5" fontWeight="bold">
                                                            <textPath xlinkHref="#circlePath">
                                                                &nbsp; &#183; &nbsp; FLY &nbsp; HIGH &nbsp; WITH &nbsp; SOCIAL &nbsp; EAGLE &nbsp; &#183; &nbsp; FLY &nbsp; HIGH &nbsp; WITH &nbsp; SOCIAL &nbsp; EAGLE
                                                            </textPath>
                                                        </text>
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='home-end-to-end-team-sections py-5 overflow-hidden' id="home-below-section">
                <div className='container' >
                    <div className='row'>
                        <div className='col-lg-6 my-auto'>
                            <div className=''>
                                <h5 className='left-para-subContent arial-bold' data-aos="fade-up-right" data-aos-duration="1500">Complete End-to-End Digital Marketing
                                    <br className='break-tag' /> Training which is Functional, AI Powered,
                                    <br className='break-tag' /> 100% Hands-on, Agency-Based & <br className='break-tag' />
                                    supported by Who’s Who of the Industry</h5>
                                <div className='position-relative mt-lg-5 mt-md-5 mt-0 width-left-circle-content' data-aos="zoom-in" data-aos-duration="1500">
                                    <div class="circle-div">
                                        <a href="#" class="circle-center-link">
                                            <div class="circle-center-left">
                                                <img className='circle-center-rotate-img' src={Favicon} alt="Tick" />
                                            </div>
                                            <div id="circle" className="rotate">
                                                <svg width="300px" height="300px" viewBox="0 0 300 300">
                                                    <defs>
                                                        <path id="circlePath" d="M 150, 150 m -60, 0 a 60,60 0 0,1 120,0 a 60,60 0 0,1 -120,0"></path>
                                                    </defs>
                                                    <circle cx="150" cy="150" r="60" fill="none"></circle>
                                                    <g>
                                                        <use href="#circlePath" fill="none"></use>
                                                        <text fill="#fff" fontSize="13.6" fontWeight="bold">
                                                            <textPath href="#circlePath">
                                                                &nbsp; &#183; &nbsp; SOCIAL &nbsp; EAGLE &nbsp; ACADEMY &nbsp; &#183; &nbsp; SOCIAL &nbsp; EAGLE &nbsp; ACADEMY &nbsp;
                                                            </textPath>
                                                        </text>
                                                    </g>
                                                </svg>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div data-aos="fade-up-left" data-aos-duration="1500">
                                <img className='image-sections' src={TeamHome} alt=""></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='home-choose-us-section overflow-hidden py-5' >
                <div className='container'>
                    <div className='section-handson-sticky'>
                        <h2 className='section-heading mb-5' data-aos="zoom-in" data-aos-duration="1500">Why People Choose Us?</h2>
                    </div>
                    <div className='row justify-content-center py-3'>
                        <div className='col-lg-6' >
                            <div className='' data-aos="fade-right" data-aos-duration="1500">
                                <h5 className='choose-us-sub-heading'>Agency-Based Training</h5>
                            </div>
                        </div>
                        <div className='col-lg-6' >
                            <div className='' data-aos="fade-left" data-aos-duration="1500">
                                <p className='choose-us-para-text'>From an Agency which has made Businesses over <br className='break-tag' /> 100 crores in Revenue</p>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className='row justify-content-center py-3'>
                        <div className='col-lg-6' >
                            <div className='' data-aos="fade-right" data-aos-duration="1500">
                                <h5 className='choose-us-sub-heading'>Year Long Support +
                                    Active Community</h5>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='' data-aos="fade-left" data-aos-duration="1500">
                                <p className='choose-us-para-text'>52 Doubt Clearing Sessions
                                    <br className='break-tag' />  Monthly Superstar Sessions + Monthly Book Sessions</p>
                                <p className='choose-us-para-text'>800+ Positive Like-Minded People & growing
                                </p>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className='row justify-content-center py-3'>
                        <div className='col-lg-6'>
                            <div className='' data-aos="fade-right" data-aos-duration="1500">
                                <h5 className='choose-us-sub-heading'>Entrepreneurship Skillset + <br className='break-tag' />
                                    Mindset Training </h5>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='' data-aos="fade-left" data-aos-duration="1500">
                                <p className='choose-us-para-text'>Through Online + Offline Sessions
                                </p>
                                <p className='choose-us-para-text'>Market-Ready Knowledge which works right now
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='hands-on-section py-5'>
                <div className='container'>
                    <div className='section-handson-sticky overflow-hidden' data-aos="zoom-in" data-aos-duration="1500">
                        <h2 className='section-heading'>Hands-on. Disruptive. Result-Oriented.</h2>
                        <h5 className='section-sub-heading mb-5'>See how we teach our courses.</h5>
                    </div>
                    <div className='row'>
                        <div className='col-lg-3 position-relative'>
                            <div className='hands-on-left-content' >
                                <ul className='list-unstyled hands-on-content-mob pl-0'>
                                    {['Methodology', 'Instructors', 'Business 101', 'Mentorship', 'Knowledge', 'Training', 'Community', 'Continuous Learning', 'Resources', 'Entrepreneurship'].map((text, index) => (
                                        <li key={index}>
                                            <span
                                                className={`hands-on-left-text ${activeTab === index ? 'active' : ''}`}
                                                onClick={() => handleTabClick(index)}
                                                ref={el => videoRefs.current[index] = el}
                                            >
                                                {text}
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className='col-lg-9'>
                            <div className='hands-on-right-section' >
                                {handsOnContent.map((item, index) => (
                                    <div
                                        key={index}
                                        className='hands-on-right-video'
                                        ref={el => videoRefs.current[index] = el}
                                    >
                                        <wistia-player
                                            style={{
                                                width: '100%',
                                                height: 'auto',
                                                borderRadius: '12px'
                                            }}
                                            media-id={item.mediaId}
                                            player-color="#D32B3A"
                                        ></wistia-player>
                                        <p className='right-side-video-content-para'>{item.mediaPara}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='home-student-says-section overflow-hidden py-5'>
                <div className='container mb-lg-0 mb-md-0 mb-5'>
                    <div className='section-handson-sticky'>
                        <h2 className='section-heading mb-5' data-aos="zoom-in" data-aos-duration="2500">What Our Students Say</h2>
                    </div>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='' data-aos="fade-up" data-aos-duration="1500">
                                <OwlCarousel
                                    className="owl-theme"
                                    loop
                                    autoplay={true}
                                    items="1"
                                    dots={false}
                                    smartSpeed={2000}
                                    nav={true}
                                    margin={20}
                                    center={true}
                                    autoplayTimeout={2000}
                                    responsive={{
                                        360: {
                                            items: "1",
                                        },
                                        414: {
                                            items: "1",
                                        },
                                        670: {
                                            items: "2",
                                        },
                                        992: {
                                            items: "2",
                                        },
                                        1200: {
                                            items: "3",
                                        },
                                    }}
                                >
                                    {videos.map((item, index) => {
                                        return (
                                            <div className='students-testi-card' key={index}>
                                                <div className=''>
                                                    <div className='position-relative'>
                                                        <div  onClick={() => {
                                                            setOpenVideo(true);
                                                            setCurrentMediaId(item.mediaId);
                                                        }}>
                                                            <img className='stud-testi-card-img-home' src={item.thumbImg} alt="Thumbnail"  />
                                                            <div style={{ position: 'absolute', bottom: '5%', left: '3%', }}>
                                                                <img className='play-icon-video-testi pulse-button' src={PlayIconTesti} alt=""></img>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='students-testi-card-content'>
                                                    <h5 className='students-testi-heading'>{item.heading}</h5>
                                                    <p className='mb-0 students-testi-para'>{item.para}</p>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    {/* <div className='students-testi-card'>
                                        <div className=''>
                                            <div className='position-relative'>
                                                <a href="https://socialeagle.wistia.com/medias/tsg3172b6r" target='_blank'>
                                                    <img src={Testi1} alt="Thumbnail" style={{ width: '100%', height: '100%' }} />
                                                    <div style={{ position: 'absolute', bottom: '5%', left: '3%', }}>
                                                        <img className='play-icon-video-testi' src={PlayIconTesti} alt=""></img>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className='students-testi-card-content'>
                                            <h5 className='students-testi-heading'>How Mukesh bought his first car in 90 days Through Freelancing</h5>
                                            <p className='mb-0 students-testi-para'>Having spent more than 5L rupees in various other courses, Mukesh was not able to find the depth of digital marketing until he found the right knowledge and mentors at Social Eagle</p>
                                        </div>
                                    </div> */}
                                    {/* <div className='students-testi-card'>
                                        <div className=''>
                                            <div className='position-relative'>
                                                <a href="https://socialeagle.wistia.com/medias/z9bgdbzncd" target='_blank'>
                                                    <img src={Testi2} alt="Thumbnail" style={{ width: '100%', height: '100%' }} />
                                                    <div style={{ position: 'absolute', bottom: '5%', left: '3%', }}>
                                                        <img className='play-icon-video-testi' src={PlayIconTesti} alt=""></img>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className='students-testi-card-content'>
                                            <h5 className='students-testi-heading'>How Ramesh was able to get 4 New Clients Every Month for his agency services.
                                            </h5>
                                            <p className='mb-0 students-testi-para'>
                                                Ramesh quickly shifted from being a professor to owning an agency within just 40 days of starting to learn Digital Marketing. Mastering everything from strategy creation to high-ticket sales boosted his confidence, securing him four clients monthly. He's now expanding his team.</p>
                                        </div>
                                    </div>
                                    <div className='students-testi-card'>
                                        <div className=''>
                                            <div className='position-relative'>
                                                <a href="https://socialeagle.wistia.com/medias/80z8jx9epp" target='_blank'>
                                                    <img src={Testi3} alt="Thumbnail" style={{ width: '100%', height: '100%' }} />
                                                    <div style={{ position: 'absolute', bottom: '5%', left: '3%', }}>
                                                        <img className='play-icon-video-testi' src={PlayIconTesti} alt=""></img>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className='students-testi-card-content'>
                                            <h5 className='students-testi-heading'>How Jeyasudha was able to earn 6+ Lakhs as an housewife.
                                            </h5>
                                            <p className='mb-0 students-testi-para'> Jeyasudha, a former employee on a career break post-marriage, sought work-from-home opportunities. Discovering Social Eagle Academy's Digital Entrepreneurship Initiator Course, she secured her first client in under 30 days and now serves over 5 clients, earning over 6 lakh in revenue within 3 months.</p>
                                        </div>
                                    </div>
                                    <div className='students-testi-card'>
                                        <div className=''>
                                            <div className='position-relative'>
                                                <a href="https://socialeagle.wistia.com/medias/nwxcyuuvfw" target='_blank'>
                                                    <img src={Testi4} alt="Thumbnail" style={{ width: '100%', height: '100%' }} />
                                                    <div style={{ position: 'absolute', bottom: '5%', left: '3%', }}>
                                                        <img className='play-icon-video-testi' src={PlayIconTesti} alt=""></img>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className='students-testi-card-content'>
                                            <h5 className='students-testi-heading'>How Naveen was able to make More than 7 Lakhs as an agency owner after failing in his 3 other businesses.
                                            </h5>
                                            <p className='mb-0 students-testi-para'>
                                                Naveen, a first-time entrepreneur and mechanical engineer, had several failed businesses before joining Social Eagle. Now, after completing the Digital Entrepreneurship Initiator Program, he successfully runs a five-figure agency alongside his engineering career.</p>
                                        </div>
                                    </div>
                                    <div className='students-testi-card'>
                                        <div className=''>
                                            <div className='position-relative'>
                                                <a href="https://socialeagle.wistia.com/medias/mjxnm5v1z9" target='_blank'>
                                                    <img src={Testi5} alt="Thumbnail" style={{ width: '100%', height: '100%' }} />
                                                    <div style={{ position: 'absolute', bottom: '5%', left: '3%', }}>
                                                        <img className='play-icon-video-testi' src={PlayIconTesti} alt=""></img>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className='students-testi-card-content'>
                                            <h5 className='students-testi-heading'>How a Corporate Employee like Priya was able to make more  than 5 Lakh with her side business using Digital Marketing </h5>
                                            <p className='mb-0 students-testi-para'>Priya, originally an employee, joined Social Eagle Academy to learn digital marketing and assist her company. Discovering her passion, she began side hustles alongside her job, earning over 5 lakhs in five months.</p>
                                        </div>
                                    </div>
                                    <div className='students-testi-card'>
                                        <div className=''>
                                            <div className='position-relative'>
                                                <a href="https://socialeagle.wistia.com/medias/9rwle03cb0" target='_blank'>
                                                    <img src={Testi6} alt="Thumbnail" style={{ width: '100%', height: '100%' }} />
                                                    <div style={{ position: 'absolute', bottom: '5%', left: '3%', }}>
                                                        <img className='play-icon-video-testi' src={PlayIconTesti} alt=""></img>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className='students-testi-card-content'>
                                            <h5 className='students-testi-heading'>How Ganesh was able to make 26 Lakh in Profit with 1000 Rupees.</h5>
                                            <p className='mb-0 students-testi-para'>Ganesh, a real estate business owner frustrated with unreliable agencies, self-learned digital marketing but struggled with the terminology. Joining Social Eagle Academy resolved his doubts in a month. He ran successful ads, earning 26 lakhs from one sale. He then launched his agency and became a digital strategist.</p>
                                        </div>
                                    </div>
                                    <div className='students-testi-card'>
                                        <div className=''>
                                            <div className='position-relative'>
                                                <a href="https://socialeagle.wistia.com/medias/t9kj0hkvg6" target='_blank'>
                                                    <img src={Testi7} alt="Thumbnail" style={{ width: '100%', height: '100%' }} />
                                                    <div style={{ position: 'absolute', bottom: '5%', left: '3%', }}>
                                                        <img className='play-icon-video-testi' src={PlayIconTesti} alt=""></img>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className='students-testi-card-content'>
                                            <h5 className='students-testi-heading'>How Jamuna is making a profit of over 1 Lakh rupees for her Gift Shop Business.
                                            </h5>
                                            <p className='mb-0 students-testi-para'>
                                                Jamuna was struggling to find a way to promote her offline gift shop in online. She tried multiple ways which she found was not helpful until she joined the Digital Entrepreneurship Initiator Program at Social Eagle.</p>
                                        </div>
                                    </div>
                                    <div className='students-testi-card'>
                                        <div className=''>
                                            <div className='position-relative'>
                                                <a href="https://socialeagle.wistia.com/medias/p19bytz64q" target='_blank'>
                                                    <img src={Testi8} alt="Thumbnail" style={{ width: '100%', height: '100%' }} />
                                                    <div style={{ position: 'absolute', bottom: '5%', left: '3%', }}>
                                                        <img className='play-icon-video-testi' src={PlayIconTesti} alt=""></img>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className='students-testi-card-content'>
                                            <h5 className='students-testi-heading'>How Giriesh helped his client make over $3000 with an investment of 1000 rupees.</h5>
                                            <p className='mb-0 students-testi-para'>Having spent more than 5L rupees in various other courses, Mukesh was not able to find the depth of digital marketing until he found the right knowledge and mentors at Social Eagle</p>
                                        </div>
                                    </div>
                                    <div className='students-testi-card'>
                                        <div className=''>
                                            <div className='position-relative'>
                                                <a href="https://socialeagle.wistia.com/medias/d6jzrvsx48" target='_blank'>
                                                    <img src={Testi9} alt="Thumbnail" style={{ width: '100%', height: '100%' }} />
                                                    <div style={{ position: 'absolute', bottom: '5%', left: '3%', }}>
                                                        <img className='play-icon-video-testi' src={PlayIconTesti} alt=""></img>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className='students-testi-card-content'>
                                            <h5 className='students-testi-heading'>How Vinothini was able to grow her offline Boutique’s sales by 3X during off-season. </h5>
                                            <p className='mb-0 students-testi-para'>Vinothini runs a boutique for kids party wear online and offline, a unique niche. After taking multiple courses with mixed results, she found success with mentorship from Social Eagle Academy. Implementing her new knowledge, she received over 1000 inquiries in the first week and made sales exceeding 8 lakhs within 30 days.</p>
                                        </div>
                                    </div>
                                    <div className='students-testi-card'>
                                        <div className=''>
                                            <div className='position-relative'>
                                                <a href="https://socialeagle.wistia.com/medias/jaiza2qyw4" target='_blank'>
                                                    <img src={Testi10} alt="Thumbnail" style={{ width: '100%', height: '100%' }} />
                                                    <div style={{ position: 'absolute', bottom: '5%', left: '3%', }}>
                                                        <img className='play-icon-video-testi' src={PlayIconTesti} alt=""></img>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className='students-testi-card-content'>
                                            <h5 className='students-testi-heading'>Richerd Made over 23 Lakh and also increased this Team Size by 3X.</h5>
                                            <p className='mb-0 students-testi-para'>Richard, a digital marketer and business owner, faced financial struggles and lost confidence. Joining Social Eagle’s program connected him with supportive peers, boosting his motivation. He earned over 20 lakhs and won the Eagle Award at the TN Digital Summit.</p>
                                        </div>
                                    </div>
                                    <div className='students-testi-card'>
                                        <div className=''>
                                            <div className='position-relative'>
                                                <a href="https://socialeagle.wistia.com/medias/zi2h2hlu0y" target='_blank'>
                                                    <img src={Testi11} alt="Thumbnail" style={{ width: '100%', height: '100%' }} />
                                                    <div style={{ position: 'absolute', bottom: '5%', left: '3%', }}>
                                                        <img className='play-icon-video-testi' src={PlayIconTesti} alt=""></img>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className='students-testi-card-content'>
                                            <h5 className='students-testi-heading'>How Kamal turned into an Entrepreneur from an Employee while making more than 20 Lakhs in revenue.</h5>
                                            <p className='mb-0 students-testi-para'>Kamal was a Corporate Employee and was helping his wife’s small business. He wanted to learn Digital Marketing to help his wife and in the process he started his own agency. He built Social Guru and now it is one of the renowned agencies in Dindigul, Tamil Nadu.</p>
                                        </div>
                                    </div> */}
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='Home-award-section  overflow-hidden py-5'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-10'>
                            <div className='row justify-content-between'>
                                <div className='col-lg-6'>
                                    <div className='home-award-left-img-section' data-aos="zoom-in" data-aos-duration="2500">
                                        <img className='home-award-img' src={HomeAward} alt=""></img>
                                    </div>
                                </div>
                                <div className='col-lg-5 my-auto'>
                                    <div className='eagle-award-home-content' data-aos="fade-left" data-aos-duration="2500">
                                        <h1 className='eagle-award-home-left-content'>
                                            Become a <br className="break-tag" />
                                            Part of our <br className="break-tag" />
                                            Community <br className="break-tag" />
                                            & Win the <br className="break-tag" />
                                            Prestigious <br className="break-tag" />
                                            <span className='eagle-award-gradient'>Eagle Award</span>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className='results-content'>
                <CustomModal
                    open={openVideo}
                    onClickOutside={() => {
                        setOpenVideo(false);
                        setCurrentMediaId(null);
                    }}
                >
                    <div className="modal-content contact-container m-auto">
                        <div className="modal-body w-100 p-0 ">
                            <div className="close-btn">
                                <i
                                    className="fa-regular fa-circle-xmark"
                                    onClick={() => setOpenVideo(false)}
                                ></i>
                            </div>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <div className=''>
                                            <wistia-player
                                                style={{
                                                    width: '100%',
                                                    height: 420,
                                                    borderRadius: 12
                                                }}
                                                media-id={currentMediaId}
                                                player-color="#D32B3A"
                                            ></wistia-player>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </CustomModal>
            </div>

        </div>
    )
}
