import React, { useEffect } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import ContactBanner from "../Components/ContactUs/ContactBanner";
import ContactContent from "../Components/ContactUs/ContactContent";

const ContactUs = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="overflow-hidden">
      <Navbar />
      <ContactBanner />
      <ContactContent />
      <Footer />
    </div>
  );
};

export default ContactUs;
