import React, { useEffect } from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import HomeBanner from '../Components/Home/HomeBanner'
import BannerBottomCarousel from '../Components/BannerBottomCarousel'
import { HomeContent } from '../Components/Home/HomeContent'

const Home = () => {
    useEffect(() => {
        window.scroll(0, 0);
      }, []);
    return (
        <div >
            <Navbar />
            <HomeBanner />
            <BannerBottomCarousel />
            <HomeContent/>
            <Footer />
        </div>
    )
}

export default Home