import React, { useEffect } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import AboutUsBanner from "../Components/AboutUs/AboutUsBanner";
import AboutUsContent from "../Components/AboutUs/AboutUsContent";

const AboutUs = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="overflow-hidden">
      <Navbar />
      <AboutUsBanner />
      <AboutUsContent />
      <Footer />
    </div>
  );
};

export default AboutUs;
