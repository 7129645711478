import React, { useState, useRef, useEffect } from 'react';
import '@wistia/wistia-player';
import '@wistia/wistia-player';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import VideoThumbnail from '../../assets/images/home-video-thumbnail.webp';
import PlayIcon from '../../assets/images/play-icon.webp';
import ScopeImg1 from '../../assets/images/scope-1.webp';
import ScopeImg2 from '../../assets/images/scope-2.webp';
import ScopeImg3 from '../../assets/images/scope-3.webp';
import ScopeImg4 from '../../assets/images/scope-4.webp';
import ScopeImg5 from '../../assets/images/scope-5.webp';
import CustomModal from '../Model/CustomModal';
import PlayIconTesti from '../../assets/images/play-icon-testi.webp';

import Awards1 from '../../assets/images/awards/awards1.jpeg';
import HatThrowImg from '../../assets/images/Expertbannerimg.webp';
import AcademyTeamImg from '../../assets/images/awards/academyteamimg.png';

const EventsContent = () => {


    const [isPlaying, setIsPlaying] = useState(false);

    const location = useLocation();
    const [activeSection, setActiveSection] = useState(location.search || '/Results?id=Videos');

    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll('.section');
            let currentSection = activeSection;
            const headerHeight = document.querySelector('.events-heading').offsetHeight + 85; // 85px is the 'top' offset in CSS

            sections.forEach(section => {
                const rect = section.getBoundingClientRect();
                if (rect.top <= window.innerHeight / 2 + headerHeight && rect.bottom >= window.innerHeight / 2 + headerHeight) {
                    currentSection = `/Events?id=${section.id}`;
                }
            });

            if (currentSection !== activeSection) {
                setActiveSection(currentSection);
            }
            console.log('currentSection:', currentSection);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [activeSection]);

    useEffect(() => {
        if (location.search) {
            setActiveSection(location.search);
            const containerId = location.search.replace('?id=', '');
            const element = document.getElementById(containerId);
            if (element) {
                const headerHeight = document.querySelector('.events-heading').offsetHeight + 85;
                const elementPosition = element.getBoundingClientRect().top + window.scrollY - headerHeight;
                window.scrollTo({ top: elementPosition, behavior: 'smooth' });
            }
        } else {
            setActiveSection('/Events?id=TNDS');
        }
    }, [location]);

    const getActiveClass = (searchParam) => {
        console.log(`Checking if ${activeSection} matches ${searchParam}`);
        return activeSection === searchParam ? 'active' : '';
    };


    const [openVideo, setOpenVideo] = useState(false);
    const [currentMediaId, setCurrentMediaId] = useState(null);

    const videos = [
        {
            thumbImg: require('../../assets/images/testi1.webp'),
            mediaId: "tsg3172b6r",
        },
        {
            thumbImg: require('../../assets/images/testi2.webp'),
            mediaId: "z9bgdbzncd",
        },
        {
            thumbImg: require('../../assets/images/testi3.webp'),
            mediaId: "80z8jx9epp",
        },
    ]

    const navigate = useNavigate();

    return (
        <div className='results-content  py-5'>
            <div className='container'>
                <div className='events-heading py-2 d-flex justify-content-center mb-5'>
                    <div className='d-flex justify-content-center align-items-center gap-lg-4 gap-md-4 gap-3'>
                        <Link to="/Events?id=TNDS" data-aos="zoom-out-right" data-aos-duration="1500" className={`events-tab ${getActiveClass('/Events?id=TNDS')}`}>Tamil Nadu Digital Summit </Link>
                        <Link to="/Events?id=Convocation" data-aos="zoom-out-right" data-aos-duration="1500" className={`events-tab ${getActiveClass('/Events?id=Convocation')}`}>Convocation</Link>
                    </div>
                </div>
            </div>
            <div className='pt-3 mb-5 section overflow-hidden' id="TNDS">
                <div className='container mb-5 '>
                    <h5 className='contacth2  mb-3' data-aos="zoom-out" data-aos-duration="1500">What is TNDS?</h5>
                    <div className='row '>
                        <div className='col-lg-6' data-aos="fade-up-right" data-aos-duration="1500">
                            <p className='choose-us-para-text text-justify'>The TN Digital Summit is a stellar event going to be held in Tamil Nadu, India, aimed at bringing together top-notch professionals, subject matter experts, and blooming enthusiasts from the digital industry. The TN Digital Summit is organized by Social Eagle, a pioneering agency in the field of Digital Marketing and Growth. With their expertise and experience in the digital space, attendees can expect well-curated and impactful line of events that delivers immensely valuable insights, innumerable networking opportunities, and actionable tested strategies for success.</p>
                            <p className='choose-us-para-text text-justify'>The summit will serve as a platform for discussing the latest trends, upcoming innovations, and existing challenges within the digital space, with a focus on empowering businesses and individuals to leverage digital technologies for growth and success. It will be a one stop spot for you to learn from the pioneers and rulers of the industry .The TN Digital Summit's status as the biggest event in the digital space underscores its importance as a premier gathering for all stakeholders invested in the future of digital transformation.</p>
                        </div>
                        <div className='col-lg-6' data-aos="fade-up-left" data-aos-duration="1500">
                            <div className='position-relative'>
                                {!isPlaying ? (
                                    <div onClick={() => setIsPlaying(true)} style={{ cursor: 'pointer' }}>
                                        <img src={VideoThumbnail} alt="Thumbnail" style={{ width: '100%', height: '100%' }} />
                                        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                            <img className='play-icon-video ' src={PlayIcon} alt=""></img>
                                        </div>
                                    </div>
                                ) : (
                                    <wistia-player
                                        style={{
                                            width: '100%',
                                            height: 'auto',
                                            borderRadius: '12px'
                                        }}
                                        media-id="h0i55nsez3"
                                        player-color="#D32B3A"
                                    ></wistia-player>)}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container mb-5 '>
                    <h5 className='contacth2  mb-4'>Eagle Awards</h5>
                    <div className='row mb-5'>
                        <div className='col-lg-3 '>
                            <img className='eagle-awards-img' src={Awards1} alt=""></img>
                            <img className='eagle-awards-img' src={Awards1} alt=""></img>
                        </div>
                        <div className='col-lg-6 mt-auto '>
                            <img className='eagle-awards-img' src={Awards1} alt=""></img>
                        </div>
                        <div className='col-lg-3'>
                            <img className='eagle-awards-img' src={Awards1} alt=""></img>
                            <img className='eagle-awards-img' src={Awards1} alt=""></img>
                        </div>
                        <div className='col-lg-3'>
                            <img className='eagle-awards-img' src={Awards1} alt=""></img>
                        </div>
                        <div className='col-lg-3'>
                            <img className='eagle-awards-img' src={Awards1} alt=""></img>
                        </div>
                        <div className='col-lg-3'>
                            <img className='eagle-awards-img' src={Awards1} alt=""></img>
                        </div>
                        <div className='col-lg-3'>
                            <img className='eagle-awards-img' src={Awards1} alt=""></img>
                        </div>
                    </div>
                    <h5 className='contacth2  mb-4'>Testimonials</h5>
                    <div className='row '>
                        {videos.map((item, index) => {
                            return (
                                <div className='col-lg-4 mb-4 col-md-6' data-aos="fade-up" data-aos-duration="2000" key={index}>
                                    <div className=''>
                                        <div className='' onClick={() => {
                                            setOpenVideo(true);
                                            setCurrentMediaId(item.mediaId);
                                        }}>
                                            <div className='position-relative'>
                                                <div >
                                                    <img src={item.thumbImg} alt="Thumbnail" className="testi-events" />
                                                    <div style={{ position: 'absolute', bottom: '5%', left: '3%', }}>
                                                        <img className='play-icon-video-testi pulse-button' src={PlayIconTesti} alt=""></img>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className='text-center mt-4'>
                        <button type="button" class="btn btn-explore-course">Apply for the “Eagle Awards”</button>
                    </div>
                </div>
                <div className='bg-scope-tnds-sections py-5'>
                    <div className='container'>
                        <div className='d-flex justify-content-center'><h5 className='contacth2 mb-5'>The Significance</h5></div>
                        <div className='row justify-content-center mb-5'>
                            <div className='col-lg-10'>
                                <div className='row  py-2'>
                                    <div className='col-lg-5' >
                                        <h5 className='scope-heading' data-aos="zoom-out-right" data-aos-duration="1500">Scope and Diversity</h5>
                                    </div>
                                    <div className='col-lg-7'>
                                        <p className='scope-para mb-0'  data-aos="zoom-out-right" data-aos-duration="1500">The summit covers a broad range of topics encompassing digital marketing, digital sales, tech tools, e-commerce, entrepreneurship, content creation, and more. Its comprehensive agenda ensures that it appeals to a diverse audience with varied interests and expertise within the digital space.</p>
                                    </div>
                                </div>
                                <hr />
                                <div className='row   py-2'>
                                    <div className='col-lg-5' >
                                        <h5 className='scope-heading'  data-aos="zoom-out-left" data-aos-duration="1500">High-profile Speakers and Participants</h5>
                                    </div>
                                    <div className='col-lg-7' >
                                        <p className='scope-para mb-0'  data-aos="zoom-out-left" data-aos-duration="1500">The summit attracts top-tier speakers, thought leaders, and industry experts who share their insights, experiences, and strategies for success.</p>
                                    </div>
                                </div>
                                <hr />
                                <div className='row     py-2'>
                                    <div className='col-lg-5'>
                                        <h5 className='scope-heading'  data-aos="zoom-out-right" data-aos-duration="1500">Networking Opportunities</h5>
                                    </div>
                                    <div className='col-lg-7'  >
                                        <p className='scope-para mb-0'  data-aos="zoom-out-right" data-aos-duration="1500">With a large attendance of professionals and stakeholders from across the digital landscape, the summit provides unparalleled networking opportunities. Attendees can connect with peers, potential collaborators, clients, and mentors, fostering valuable relationships that can lead to business growth and professional development.</p>
                                    </div>
                                </div>
                                <hr />
                                <div className='row   py-2'>
                                    <div className='col-lg-5' >
                                        <h5 className='scope-heading' data-aos="zoom-out-left" data-aos-duration="1500">Innovation and Trends</h5>
                                    </div>
                                    <div className='col-lg-7'  >
                                        <p className='scope-para mb-0' data-aos="zoom-out-left" data-aos-duration="1500">The summit serves as a platform for showcasing the latest innovations, trends, and technologies shaping the digital industry. From cutting-edge tools and platforms to emerging best practices, attendees gain valuable insights into staying ahead of the curve and driving innovation for their growth.</p>
                                    </div>
                                </div>
                                <hr />
                                <div className='row  py-2'>
                                    <div className='col-lg-5' >
                                        <h5 className='scope-heading'  data-aos="zoom-out-right" data-aos-duration="1500">Scope and Diversity</h5>
                                    </div>
                                    <div className='col-lg-7'  >
                                        <p className='scope-para mb-0'  data-aos="zoom-out-right" data-aos-duration="1500">Given its stature as the biggest event in the digital space, the TN Digital Summit exerts a significant influence on the direction and evolution of the digital ecosystem. It serves as a catalyst for change, driving conversations, collaborations, and initiatives that propel the industry forward.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='text-center'>
                            <button type="button" class="btn btn-explore-course">Join the Waiting List</button>
                        </div>
                    </div>
                </div>
                <div className='tnds-2025 py-5'>
                    <div className='container '>
                        <div className='row'>
                            <div className='col-lg-6 my-auto'>
                                <div  data-aos="zoom-out-down" data-aos-duration="1500">
                                    <h5 className='font-bold text-white mb-2'>Join us at T&C 2025</h5>
                                    <h1 className='font-bold text-white mt-2'>Want to do it all again?</h1>
                                </div>
                            </div>
                            <div className='col-lg-6 my-auto'>
                                <div  data-aos="zoom-out-left" data-aos-duration="1500">
                                    <p className='choose-us-para-text text-white pb-4'>Join us at Traffic & Conversion Summit 2025 - the ULTIMATE digital marketing event which will take place on March 11-13 at Caesars Forum, Las Vegas. Plus... right now you can get your ticket for the lowest price you'll EVER see.</p>
                                    <button type="button" class="btn btn-book-expert-call ">Register & Save 70% Now</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className='section' id="Convocation">
                <div className='container'>
                    <h5 className='contacth2  mt-2'>Convocation</h5>
                    <h5 className='font-bold mb-2'>Where the Eagles come together</h5>
                    <h5 className='font-bold mb-5'>An opportunity to connect, network & to be recognised </h5>
                    <div className='row mb-5'>
                        <div className='col-lg-3'>
                            <img className='eagle-awards-img' src={HatThrowImg} alt=""></img>
                            <img className='eagle-awards-img' src={HatThrowImg} alt=""></img>
                        </div>
                        <div className='col-lg-6 my-auto'>
                            <img className='eagle-awards-img' src={HatThrowImg} alt=""></img>
                        </div>
                        <div className='col-lg-3'>
                            <img className='eagle-awards-img' src={HatThrowImg} alt=""></img>
                            <img className='eagle-awards-img' src={HatThrowImg} alt=""></img>
                        </div>
                    </div>
                    <h5 className='contacth2  mt-2 mb-5'>Become a part of our Community</h5>
                    <div className='row '>
                        <div className='col-lg-5'>
                            <div className='position-relative'>
                                <img className='eagle-awards-img' src={AcademyTeamImg} alt=""></img>
                            </div>
                        </div>
                        <div className='col-lg-7 my-auto'>
                            <p className='choose-us-para-text text-justify'>
                                Why Social Eagle?
                                At Social Eagle Academy, we think that education and practical experience are the keys to success. For this reason, we provide extensive training programmes that span a range of subjects, from fundamental technical skills to an in-depth understanding of industry-specific knowledge. Our training is created to be both engaging and practical, and our knowledgeable instructors are committed to assisting our students in excelling in digital marketing domain. </p>
                            <p className='choose-us-para-text text-justify'>
                                Why Social Eagle?
                                At Social Eagle Academy, we think that education and practical experience are the keys to success. For this reason, we provide extensive training programmes that span a range of subjects, from fundamental technical skills to an in-depth understanding of industry-specific knowledge. Our training is created to be both engaging and practical, and our knowledgeable instructors are committed to assisting our students in excelling in digital marketing domain.
                                We at Social Eagle Academy are dedicated to providing our students with the best training possible so they can achieve their professional and entrepreneurial goals. You can be confident that your journey will be successful with our personalised direction and assistance. Trust us, we offer valuable business and entrepreneur mentorship, assisting you in landing your ideal career in the area of digital marketing.
                            </p>
                        </div>

                    </div>
                    <div className='text-center mt-4 mb-5'>
                        <button onClick={() => navigate('/Course')} type="button" class="btn btn-explore-course">Explore Course</button>
                    </div>
                    <h5 className='contacth2  mb-5'>What happens in our Convocation
                    </h5>
                    <div className='row mb-5'>
                        {videos.map((item, index) => {
                            return (
                                <div className='col-lg-4 mb-4 col-md-6' data-aos="fade-up" data-aos-duration="2000" key={index}>
                                    <div className=''>
                                        <div className='' onClick={() => {
                                            setOpenVideo(true);
                                            setCurrentMediaId(item.mediaId);
                                        }}>
                                            <div className='position-relative'>
                                                <div >
                                                    <img src={item.thumbImg} alt="Thumbnail" className="testi-events" />
                                                    <div style={{ position: 'absolute', bottom: '5%', left: '3%', }}>
                                                        <img className='play-icon-video-testi pulse-button' src={PlayIconTesti} alt=""></img>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <h5 className='contacth2  mb-5'>Get Inspired here
                    </h5>
                    <div className='row mb-5'>
                        {videos.map((item, index) => {
                            return (
                                <div className='col-lg-4 mb-4 col-md-6' data-aos="fade-up" data-aos-duration="2000" key={index}>
                                    <div className=''>
                                        <div className='' onClick={() => {
                                            setOpenVideo(true);
                                            setCurrentMediaId(item.mediaId);
                                        }}>
                                            <div className='position-relative'>
                                                <div >
                                                    <img src={item.thumbImg} alt="Thumbnail" className="testi-events" />
                                                    <div style={{ position: 'absolute', bottom: '5%', left: '3%', }}>
                                                        <img className='play-icon-video-testi pulse-button' src={PlayIconTesti} alt=""></img>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

            <CustomModal
                open={openVideo}
                onClickOutside={() => {
                    setOpenVideo(false);
                    setCurrentMediaId(null);
                }}
            >
                <div className="modal-content contact-container m-auto">
                    <div className="modal-body w-100 p-0 ">
                        <div className="close-btn">
                            <i
                                className="fa-regular fa-circle-xmark"
                                onClick={() => setOpenVideo(false)}
                            ></i>
                        </div>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className=''>
                                        <wistia-player
                                            style={{
                                                width: '100%',
                                                height: 420,
                                                borderRadius: 12
                                            }}
                                            media-id={currentMediaId}
                                            player-color="#D32B3A"
                                        ></wistia-player>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CustomModal>
        </div>
    )
}

export default EventsContent