import React from 'react'
import CustomModal from './CustomModal';

const ExitIntentPopup = ({ open, setOpen }) => {

    return (
        <div className='exitIntentPopup'>
            <CustomModal
                open={open}
                onClickOutside={() => {
                    setOpen(false);
                }}
            >
                <div className="modal-content conlastdivmodel p-4">
                    <div className="close-btn">
                        <i
                            className="fa-regular fa-circle-xmark"
                            onClick={() => setOpen(false)}
                        ></i>
                    </div>
                    <h4 className='font-bold text-center text-light mt-3 mb-4'>Before You Leave, Check This Out!</h4>
                    <h6 className=' text-center text-light lh-base small mb-2'>Thanks for visiting our social eagle academy! Sign up for our Digital Entrepreneurship Expert Call and get exclusive access to our latest courses.</h6>
                    <div className='my-3'>
                        <a href="https://optin.socialeagle.in/#/apply" data-aos="fade-right" data-aos-duration="1500" target='_blank'>
                            <button type="button" class="btn btn-book-expert-call">Book Expert Call</button>
                        </a>
                    </div>
                </div>
            </CustomModal>
        </div>
    )
}

export default ExitIntentPopup